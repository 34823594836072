import ITeacherDataService from "@/services/ITeacherDataService";
import Teacher from "@/models/Teacher";
import { inject, injectable } from "inversify";
import IModelFactory from "@/services/IModelFactory";
import TYPES from "@/types";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import createTeacher from "@/graphql/teachers/createTeacher.graphql";
import updateTeacher from "@/graphql/teachers/updateTeacher.graphql";
import deleteTeacher from "@/graphql/teachers/deleteTeacher.graphql";
import allTeachers from "@/graphql/teachers/allTeachers.graphql";
import getTeacher from "@/graphql/teachers/getTeacher.graphql";
import updateUser from "@/graphql/users/updateUser.graphql";
import { INVALID_ID } from "@/models/Model";
import User from "@/models/User";

@injectable()
export default class TeacherDataService implements ITeacherDataService {
  constructor(
    @inject(TYPES.IModelFactory) private modelFactory: IModelFactory,
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {}

  deleteTeacher(teacher: Teacher): Promise<boolean> {
    return this.backendApi
      .apolloMutation("deleteTeacher", deleteTeacher, { id: teacher.id })
      .then(() => {
        return true;
      });
  }

  getTeachers(): Promise<Teacher[]> {
    return this.backendApi.apolloQuery<Teacher[]>("allTeachers", allTeachers);
  }

  saveTeacher(teacher: Teacher): Promise<Teacher> {
    if (teacher.id === INVALID_ID) {
      return this.backendApi.apolloMutation<Teacher>(
        "createTeacher",
        createTeacher,
        {
          first_name: teacher.first_name,
          last_name: teacher.last_name,
          birthday: teacher.birthday,
          entry_date: teacher.entry_date,
          exit_date: teacher.exit_date,
          image: teacher.image ? teacher.image : "",
        }
      );
    } else {
      return this.backendApi.apolloMutation<Teacher>(
        "updateTeacher",
        updateTeacher,
        {
          id: teacher.id,
          first_name: teacher.first_name,
          last_name: teacher.last_name,
          birthday: teacher.birthday,
          entry_date: teacher.entry_date,
          exit_date: teacher.exit_date,
          image: teacher.image ? teacher.image : "",
        }
      );
    }
  }

  getTeacherById(id: string): Promise<Teacher> {
    return this.backendApi.apolloQuery<Teacher>("teacher", getTeacher, {
      id,
    });
  }

  getTeacherNameWithId(teacher: Teacher) {
    return `(#${teacher.id}) ${teacher.full_name}`;
  }

  createTeacher(
    first_name: string,
    last_name: string,
    entry_date: string,
    exit_date: string,
    birthday: string
  ): Promise<Teacher> {
    return this.backendApi.apolloMutation<Teacher>(
      "createTeacher",
      createTeacher,
      {
        first_name,
        last_name,
        entry_date,
        exit_date,
        birthday,
      }
    );
  }
}
