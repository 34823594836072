


































import { Component, Prop } from "vue-property-decorator";
import i18n from "@/i18n";
import { HumanFriendlyError } from "@/models/HumanFriendlyError";
import ErrorAlert from "@/views/components/common/ErrorAlert.vue";
import PageButton from "@/views/components/common/buttons/page/PageButton.vue";
import IDialog from "@/views/components/common/dialog/IDialog.vue";
import { mixins } from "vue-class-component";
import VisibleVModel from "@/views/mixins/VisibleVModel.vue";

@Component({
  components: { IDialog, PageButton, ErrorAlert },
})
export default class IConfirmDialog extends mixins(VisibleVModel) {
  @Prop({ default: i18n.t("delete.question") }) readonly question!: string;
  @Prop({ default: i18n.t("delete.title") }) heading!: string;
  @Prop({ default: i18n.t("actions.cancel") }) cancelButtonText!: string;
  @Prop({ default: i18n.t("actions.confirm") }) confirmButtonText!: string;
  @Prop({ default: "red" }) confirmButtonColor!: string;
  @Prop({ default: () => Promise.resolve(true) }) action!: () => Promise<any>;
  private errors: HumanFriendlyError = new HumanFriendlyError();
  private loading: boolean = false;

  protected close() {
    this.errors.clear();
    //@ts-ignore
    this.isVisible = false;
  }

  protected confirmed() {
    this.loading = true;
    this.errors.clear();
    this.action()
      .then(() => {
        this.close();
        this.$emit("confirmed");
      })
      .catch((error) => {
        this.$notificationService.reportError(error);
        this.errors.apiError = error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
