












import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class DialogSaveButton extends Vue {
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: "" }) text!: string;
  @Prop({ default: "button" }) type!: string;

  protected get buttonText() {
    return this.text ? this.text : this.$t("actions.save");
  }
}
