import { IApiError } from "@/errors/IApiError";
import { ErrorType } from "@/enums/ErrorType";
import i18n from "@/i18n";
import { FormErrorBag } from "@/utils/FormErrorBag";
import IModelStatus from "@/models/apiTypes/IModelStatus";
import { AbstractApiError } from "@/errors/AbstractApiError";
import { ErrorCode } from "@/enums/ErrorCode";

export class HumanFriendlyError {
  private _message: string = "";
  private _errorBag: FormErrorBag = { reasons: [] };
  private _reasons: string[] = [];
  private _state?: IModelStatus | undefined = undefined;
  private _code: ErrorCode = ErrorCode.GENERIC;

  constructor(apiError?: IApiError) {
    if (apiError) {
      this.apiError = apiError;
    }
  }

  get errorBag(): FormErrorBag {
    return this._errorBag;
  }

  get message(): string {
    return this._message;
  }

  get reasons(): string[] {
    return this._reasons;
  }

  get code(): ErrorCode {
    return this._code;
  }

  clear() {
    this._message = "";
    this._errorBag = { reasons: [] };
  }

  set apiError(apiError: IApiError | Error | any) {
    this.clear();
    if (apiError instanceof AbstractApiError) {
      switch (apiError.type) {
        case ErrorType.validation:
          this._message = i18n.t("errors.validation").toString();
          break;
        default:
          this._message = apiError.message;
          this._state = undefined;
      }
      this._errorBag = FormErrorBag.fromApiError(apiError);
      this._reasons = apiError.reasons;
      this._state = apiError.state;
      this._code = apiError.code;
    } else if (apiError.hasOwnProperty("message")) {
      this._message = apiError.message;
    }
  }

  get state(): IModelStatus | undefined {
    return this._state;
  }
}
