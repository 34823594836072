export const dateTimeFormats = {
  de: {
    dateShort: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    dateLong: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    dateTime: {
      hour: "numeric",
      minute: "numeric",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
  },
};
