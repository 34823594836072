import IModelStatus from "@/models/apiTypes/IModelStatus";
import IState from "@/models/apiTypes/IState";
import State from "@/models/vos/State";

export default class ModelStatus implements IModelStatus {
  private _errors: Array<IState> = [];
  private _warnings: Array<IState> = [];

  constructor(errors: Array<IState>, warnings: Array<IState> = []) {
    errors.forEach((value, key) => {
      this._errors.push(
        new State(value.code, value.relatedModels, value?.message ?? "")
      );
    });
  }

  get errors(): Array<IState> {
    return this._errors;
  }

  get warnings(): Array<IState> {
    return this._warnings;
  }
}
