export enum RouteName {
  FORGOT_PASSWORD = "forgotPassword",
  LOGIN = "login",
  HOME = "home",

  ALL_YEARS = "allYears",
  RESET_PASSWORD = "pwreset",
  ERROR_404 = "error404",

  CLASS_SUBJECT_SHOW = "course",
  CLASS_SUBJECT_PART_SHOW = "units",
  YEAR = "year",
  CHANGE_PASSWORD = "changePassword",
  USER_PROFILE = "profile",
}
