






































import Component, { mixins } from "vue-class-component";
import ExpandWithToolbarFlat from "@/views/components/common/ExpandWithToolbarFlat.vue";
import HasExpandableTitle from "@/views/mixins/HasExpandableTitle.vue";
import IToolbar from "@/views/components/common/toolbar/IToolbar.vue";
import ItemCard from "@/views/components/common/iterator/ItemCard.vue";
import { Prop } from "vue-property-decorator";
import ItemCardVo from "@/models/vos/ItemCardVo";
import ButtonVO from "@/models/vos/ButtonVO";

@Component({
  components: { ItemCard, IToolbar, ExpandWithToolbarFlat },
})
export default class ItemCardIterator extends mixins(HasExpandableTitle) {
  @Prop({ default: () => [] }) items!: ItemCardVo[];
  @Prop({ default: () => [] }) buttons!: ButtonVO[];
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: false }) noItemTitles!: boolean;

  protected search: string = "";
  protected itemsPerPage: number = 6;

  // protected filterItems(items:ItemCardVo[],search:string){
  //     return search ? _.filter(items,(item)=> value.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase())):
  // }

  protected filterByAttributes(items: ItemCardVo[], search: string) {
    return items.filter((itemCardVo) => {
      return (
        itemCardVo.attributes.findIndex((attribute) => {
          let foundMatchingValue: boolean = !search;
          if (search) {
            foundMatchingValue =
              typeof attribute.value === "string" &&
              (attribute.value as string)
                .toLowerCase()
                .includes(search.toLowerCase());
          }

          return foundMatchingValue;
        }) !== -1
      );
    });
  }
}
