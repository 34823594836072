












import Component, { mixins } from "vue-class-component";
import HasExpandableTitle from "../../mixins/HasExpandableTitle.vue";
import ExpandWithToolbarFlat from "../common/ExpandWithToolbarFlat.vue";
import ButtonVO, { getShowButton } from "../../../models/vos/ButtonVO";
import { Prop } from "vue-property-decorator";
import ItemCardIterator from "@/views/components/common/iterator/ItemCardIterator.vue";
import Student from "@/models/Student";
import ItemCardVo from "@/models/vos/ItemCardVo";
import TYPES from "@/types";
import { lazyInject } from "@/inversify.config";
import IStudentDataService from "@/services/IStudentDataService";
import { RouteName } from "@/enums/RouteName";
import { Location } from "vue-router";
import ISubjectService from "@/services/ISubjectService";
import { ClassSubject } from "@/models/ClassSubject";

@Component({
  components: { ItemCardIterator, ExpandWithToolbarFlat },
})
export default class ClassSubjectIterator extends mixins(HasExpandableTitle) {
  @lazyInject(TYPES.ISubjectService) subjectService!: ISubjectService;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: () => [] }) buttons!: ButtonVO[];
  @Prop({ default: () => [] }) classSubjects!: ClassSubject[];

  protected get title() {
    return this.$tc("classSubject", 2);
  }

  protected get classSubjectItems(): ItemCardVo[] {
    return this.classSubjects.map((classSubject) => {
      let vo: ItemCardVo = {
        attributes: [
          {
            title: this.$tc("denomination", 1).toString(),
            value: classSubject?.denomination ?? "",
          },
          {
            title: this.$t("reqLevelShort").toString(),
            value: classSubject.subject?.req_level ?? "",
          },
          {
            title: this.$tc("schoolSubjectType", 1).toString(),
            value: this.subjectService.getSchoolSubjectTypeText(
              classSubject.subject?.schoolSubject?.type ?? 0
            ),
          },
        ],
        buttons: [
          getShowButton({
            name: this.$routes.CLASS_SUBJECT_SHOW,
            params: { class_subject_id: classSubject.id },
          }),
        ],
      };

      return vo;
    });
  }
}
