
import Vue from "vue";
import Component from "vue-class-component";
import { Model, Watch } from "vue-property-decorator";

@Component
export default class SearchModel extends Vue {
  @Model("changed") search!: string;

  protected get filter() {
    return this.search;
  }

  protected set filter(search: string) {
    this.$emit("changed", search);
  }
}
