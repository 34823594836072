import ISubjectService from "@/services/ISubjectService";
import { Subject } from "@/models/Subject";
import { inject, injectable } from "inversify";
import TYPES from "@/types";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import SchoolSubject from "@/models/SchoolSubject";
import schoolSubjects from "@/graphql/schoolSubjects/schoolSubjects.graphql";
import createSubject from "@/graphql/subjects/createSubject.graphql";
import updateSubject from "@/graphql/subjects/updateSubject.graphql";
import subjectsByClassLevel from "@/graphql/subjects/subjectsByClassLevel.graphql";
import getSchoolSubject from "@/graphql/schoolSubjects/getSchoolSubject.graphql";
import deleteSchoolSubject from "@/graphql/schoolSubjects/deleteSchoolSubject.graphql";
import createSchoolSubject from "@/graphql/schoolSubjects/createSchoolSubject.graphql";
import updateSchoolSubject from "@/graphql/schoolSubjects/updateSchoolSubject.graphql";
import updateSubjectPart from "@/graphql/subjects/parts/updateSubjectPart.graphql";
import deleteSubject from "@/graphql/subjects/deleteSubject.graphql";
import assignSubjectTeacher from "@/graphql/subjects/assignSubjectTeacher.graphql";
import createSubjectPart from "@/graphql/subjects/parts/createSubjectPart.graphql";
import deleteSubjectPart from "@/graphql/subjects/parts/deleteSubjectPart.graphql";
import getSubjectPart from "@/graphql/subjects/parts/getSubjectPart.graphql";
import updateStudentElective from "@/graphql/studentElectives/updateStudentElective.graphql";
import updateClassSubjectPartsOrder from "@/graphql/classSubjects/updateClassSubjectPartsOrder.graphql";
import updateClassSubject from "@/graphql/classSubjects/updateClassSubject.graphql";
import skipClassSubjectPart from "@/graphql/classSubjects/skipClassSubjectPart.graphql";
import revertClassSubjectPartSkip from "@/graphql/classSubjects/revertClassSubjectPartSkip.graphql";
import deleteClassSubject from "@/graphql/classSubjects/deleteClassSubject.graphql";
import createClassSubject from "@/graphql/classSubjects/createClassSubject.graphql";
import { INVALID_ID } from "@/models/Model";
import SubjectPart from "@/models/SubjectPart";
import Teacher from "@/models/Teacher";
import { ClassSubject } from "@/models/ClassSubject";
import { SchoolSubjectType } from "@/enums/SchoolSubjectType";
import { ReqLevel } from "@/enums/ReqLevel";
import i18n from "@/i18n";
import StudentElective from "@/models/StudentElective";
import { Observable, Subject as RXSubject } from "rxjs";
import ClassSubjectPart from "@/models/ClassSubjectPart";
import { map } from "rxjs/operators";

@injectable()
export default class SubjectService implements ISubjectService {
  onSchoolSubjectCreated: Observable<SchoolSubject>;
  private _schoolSubjectCreatedSubject: RXSubject<SchoolSubject>;

  constructor(
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {
    this._schoolSubjectCreatedSubject = new RXSubject<SchoolSubject>();
    this.onSchoolSubjectCreated =
      this._schoolSubjectCreatedSubject.asObservable();
  }

  deleteSubject(subject: Subject): Promise<boolean> {
    return this.backendApi
      .apolloMutation("deleteSubject", deleteSubject, { id: subject.id })
      .then(() => true);
  }

  getSubjectsByClassLevelId(classLevelId: string): Promise<Subject[]> {
    return this.backendApi
      .apolloQuery<any>("classLevel", subjectsByClassLevel, {
        id: classLevelId,
      })
      .then((response) => {
        return response.subjects;
      });
  }

  updateSubject(subject: Subject): Promise<Subject> {
    return this.backendApi.apolloMutation<Subject>(
      "updateSubject",
      updateSubject,
      {
        id: subject.id,
        req_level: subject.req_level,
        individually_assigned: subject.individually_assigned,
        class_participation_factor: subject.class_participation_factor,
        exam_factor: subject.exam_factor,
        subject_specific_factor: subject.subject_specific_factor,
        denomination: subject.denomination,
        hours_per_week: subject.hours_per_week,
        rating_enabled: subject.rating_enabled,
        teaching_style: subject.teaching_style,
        show_req_level_in_report: subject.show_req_level_in_report,
        available_in_first_term: subject.available_in_first_term,
        available_in_second_term: subject.available_in_second_term,
      }
    );
  }

  createSubject(
    class_level_id: string,
    school_subject_id: string,
    req_level: ReqLevel,
    individually_assigned: boolean,
    class_participation_factor: number,
    exam_factor: number,
    subject_specific_factor: number,
    denomination: string,
    hours_per_week: number | null,
    rating_enabled: boolean,
    available_in_first_term: boolean,
    available_in_second_term: boolean,
    show_req_level_in_report: boolean
  ): Promise<Subject> {
    return this.backendApi.apolloMutation<Subject>(
      "createSubject",
      createSubject,
      {
        class_level_id,
        school_subject_id,
        req_level,
        individually_assigned,
        class_participation_factor,
        exam_factor,
        subject_specific_factor,
        denomination,
        hours_per_week,
        rating_enabled,
        available_in_first_term,
        available_in_second_term,
        show_req_level_in_report,
      }
    );
  }

  getSchoolSubjects(): Promise<SchoolSubject[]> {
    return this.backendApi.apolloQuery<SchoolSubject[]>(
      "schoolSubjects",
      schoolSubjects
    );
  }

  getSchoolSubjectById(id: string): Promise<SchoolSubject> {
    return this.backendApi.apolloQuery<SchoolSubject>(
      "schoolSubject",
      getSchoolSubject,
      { id }
    );
  }

  deleteSchoolSubject(schoolSubject: SchoolSubject): Promise<boolean> {
    return this.backendApi.apolloMutation<boolean>(
      "deleteSchoolSubject",
      deleteSchoolSubject,
      { id: schoolSubject.id }
    );
  }

  createSchoolSubject(
    denomination: string,
    type: SchoolSubjectType,
    report_sub_title: string,
    report_sort_key: number | null
  ): Promise<SchoolSubject> {
    return this.backendApi
      .apolloMutation<SchoolSubject>(
        "createSchoolSubject",
        createSchoolSubject,
        {
          denomination,
          type,
          report_sub_title,
          report_sort_key,
        }
      )
      .then((schoolSubject) => {
        this._schoolSubjectCreatedSubject.next(schoolSubject);
        return schoolSubject;
      });
  }

  saveSchoolSubject(schoolSubject: SchoolSubject): Promise<SchoolSubject> {
    return this.backendApi.apolloMutation<SchoolSubject>(
      "updateSchoolSubject",
      updateSchoolSubject,
      {
        id: schoolSubject.id,
        denomination: schoolSubject.denomination,
        type: schoolSubject.type,
        report_sort_key: schoolSubject.report_sort_key,
        report_sub_title: schoolSubject.report_sub_title,
      }
    );
  }

  deleteSubjectPart(subjectPart: SubjectPart): Promise<boolean> {
    return this.backendApi.apolloMutation(
      "deleteSubjectPart",
      deleteSubjectPart,
      { id: subjectPart.id }
    );
  }

  saveSubjectPart(subjectPart: SubjectPart): Promise<SubjectPart> {
    if (subjectPart.id === INVALID_ID) {
      return this.backendApi.apolloMutation<SubjectPart>(
        "createSubjectPart",
        createSubjectPart,
        {
          subject_id: subjectPart.subject.id,
          denomination: subjectPart.denomination,
          description: subjectPart.description,
          rank: subjectPart.rank,
          term: subjectPart.term,
        }
      );
    } else {
      return this.backendApi.apolloMutation<SubjectPart>(
        "updateSubjectPart",
        updateSubjectPart,
        {
          id: subjectPart.id,
          denomination: subjectPart.denomination,
          description: subjectPart.description,
          rank: subjectPart.rank,
          term: subjectPart.term,
        }
      );
    }
  }

  getSubjectPartById(id: string): Promise<SubjectPart> {
    return this.backendApi.apolloQuery<SubjectPart>(
      "subjectPart",
      getSubjectPart,
      { id }
    );
  }

  assignTeacherToSubject(
    teachers: Teacher[],
    classSubject: ClassSubject
  ): Promise<ClassSubject> {
    return this.backendApi.apolloMutation(
      "assignSubjectTeacher",
      assignSubjectTeacher,
      {
        teacherIds: teachers.map((teacher) => teacher.id),
        classSubjectId: classSubject.id,
      }
    );
  }

  createSubjectPart(
    subject_id: string,
    term: number,
    rank: number | null,
    denomination: string,
    description: string
  ): Promise<SubjectPart> {
    return this.backendApi.apolloMutation<SubjectPart>(
      "createSubjectPart",
      createSubjectPart,
      {
        subject_id,
        denomination,
        description,
        rank,
        term,
      }
    );
  }

  getClassSubjectNameWithReqLevel(classSubject: ClassSubject): string {
    return `${classSubject.denomination} - ${i18n.t("reqLevelShort")} ${
      classSubject.subject.req_level
    }`;
  }

  subjectReqLevelText(subject: Subject | null | undefined): string {
    return subject && subject.req_level
      ? i18n.t(`reqLevels.${subject.req_level}`).toString()
      : "--/--";
  }

  updateStudentElective(
    id: string,
    course_denomination: string,
    hours_per_week: number,
    duration: number
  ): Promise<StudentElective> {
    return this.backendApi.apolloMutation<StudentElective>(
      "updateStudentElective",
      updateStudentElective,
      {
        id,
        course_denomination,
        hours_per_week,
        duration,
      }
    );
  }

  getSchoolSubjectTypeText(schoolSubjectType: number): string {
    return i18n.t(`schoolSubjectTypes.${schoolSubjectType}`).toString();
  }

  updateClassSubjectPartOrder(
    classSubjectId: string,
    classSubjectParts: ClassSubjectPart[]
  ): Promise<ClassSubjectPart[]> {
    return this.backendApi.apolloMutation<ClassSubjectPart[]>(
      "updateClassSubjectPartsOrder",
      updateClassSubjectPartsOrder,
      {
        id: classSubjectId,
        parts_order: classSubjectParts.map((part) => {
          return {
            id: part.id,
            term: part.term,
            rank: part.rank,
          };
        }),
      }
    );
  }

  updateClassSubject(
    classSubjectId: string,
    denomination: string,
    class_ids: string[]
  ): Promise<ClassSubject> {
    return this.backendApi.apolloMutation<ClassSubject>(
      "updateClassSubject",
      updateClassSubject,
      {
        id: classSubjectId,
        denomination,
        class_ids,
      }
    );
  }

  createClassSubject(
    subject_id: string,
    denomination: string,
    class_ids: string[]
  ): Promise<ClassSubject> {
    return this.backendApi.apolloMutation<ClassSubject>(
      "createClassSubject",
      createClassSubject,
      {
        subject_id,
        denomination,
        class_ids,
      }
    );
  }

  deleteClassSubject(item: ClassSubject): Promise<ClassSubject> {
    return this.backendApi.apolloMutation<ClassSubject>(
      "deleteClassSubject",
      deleteClassSubject,
      {
        id: item.id,
      }
    );
  }

  revertClassSubjectPartSkip(
    classSubjectPartId: string
  ): Promise<ClassSubjectPart> {
    return this.backendApi.apolloMutation<ClassSubjectPart>(
      "revertClassSubjectPartSkip",
      revertClassSubjectPartSkip,
      {
        id: classSubjectPartId,
      }
    );
  }

  skipClassSubjectPart(classSubjectPartId: string): Promise<ClassSubjectPart> {
    return this.backendApi.apolloMutation<ClassSubjectPart>(
      "skipClassSubjectPart",
      skipClassSubjectPart,
      {
        id: classSubjectPartId,
      }
    );
  }
}
