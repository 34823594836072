import i18n from "@/i18n";

export default class DateFormatter {
  static format(
    dateString: string | null | undefined,
    format: string = "dateShort"
  ) {
    return dateString ? i18n.d(new Date(dateString), format) : "";
  }
}
