export interface Permission {
  id: number;
  name: PermissionName;
}

export enum PermissionName {
  EDIT_ADMIN = "edit_admin",
  ACCESS_ADMIN = "access_admin",
  ACCESS_ALL_TEACHER = "access_all_teacher",
  EDIT_ALL_TEACHER = "edit_all_teacher",
  MANAGE_SUBJECT_PARTS = "manage_subject_parts",
  CREATE_STUDENT_REPORTS = "create_student_reports",
}
