import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import { getModule } from "vuex-module-decorators";

import Login from "@/views/Login.vue";
import PermissionsModule from "@/modules/PermissionsModule";
import NotFoundComponent from "@/views/NotFoundComponent.vue";
import { PermissionName } from "@/models/Permission";
import { RouteName } from "@/enums/RouteName";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import UserProfile from "@/views/UserProfile.vue";

Vue.use(Router);
const permissionModule: PermissionsModule = getModule(PermissionsModule);
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: RouteName.HOME,
      component: Home,
    },
    {
      path: "/app-login",
      name: RouteName.LOGIN,
      component: Login,
    },
    {
      path: "/profile",
      name: RouteName.USER_PROFILE,
      component: UserProfile,
      beforeEnter(to, from, next) {
        if (!permissionModule.isLoggedIn) {
          next(false);
        } else {
          next();
        }
      },
    },
    {
      path: "/forgotPassword",
      name: RouteName.FORGOT_PASSWORD,
      component: ForgotPassword,
      beforeEnter(to, from, next) {
        if (permissionModule.isLoggedIn) {
          next(false);
        } else {
          next();
        }
      },
    },
    {
      path: "/password/reset/:token",
      name: RouteName.RESET_PASSWORD,
      component: ResetPassword,
      beforeEnter(to, from, next) {
        if (permissionModule.isLoggedIn) {
          next(false);
        } else {
          next();
        }
      },
      props: (route) => ({
        email: route.query.email,
        token: route.params.token,
      }),
    },
    {
      name: RouteName.CLASS_SUBJECT_SHOW,
      path: "/courses/:class_subject_id",
      component: () =>
        import(
          /* webpackChunkName: "course" */ "../views/pages/classSubject/CoursePage.vue"
        ),
      props: true,
      // children: [
      //   {
      //     name: RouteName.CLASS_SUBJECT_PART_SHOW,
      //     path: "units/:class_subject_part_id",
      //     props: true,
      //     component: () =>
      //       import(
      //         /* webpackChungName: "tacsp" */ "../views/pages/classSubject/UnitPage.vue"
      //       ),
      //   },
      // ],
    },
    {
      path: "/years",
      name: RouteName.ALL_YEARS,
      component: () =>
        import("../views/pages/schoolYear/SchoolYearsOverviewPage.vue"),
      children: [
        {
          path: ":year_id",
          name: RouteName.YEAR,
          props: true,
          component: () =>
            import("../views/pages/schoolYear/SchoolYearPage.vue"),
        },
      ],
    },
    {
      path: "/404",
      name: RouteName.ERROR_404,
      component: NotFoundComponent,
    },
    { path: "*", component: NotFoundComponent },
  ],
});
router.beforeEach((to, from, next) => {
  if (
    !getModule(PermissionsModule).isLoggedIn &&
    to.name !== RouteName.LOGIN &&
    to.name !== RouteName.FORGOT_PASSWORD &&
    to.name !== RouteName.RESET_PASSWORD
  ) {
    next({ name: RouteName.LOGIN, query: { redirect: to.fullPath } });
  } else if (
    getModule(PermissionsModule).isLoggedIn &&
    (to.name === RouteName.LOGIN || to.name === RouteName.FORGOT_PASSWORD)
  ) {
    next({ name: RouteName.HOME });
  } else {
    next();
  }
});

export default router;
