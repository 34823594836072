

























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Location } from "vue-router";

@Component
export default class PageButton extends Vue {
  @Prop({ required: true }) tooltip!: string;
  @Prop({ required: true }) icon!: string;
  @Prop({ default: "primary" }) color!: string;
  @Prop({ default: "" }) href!: string;
  @Prop({
    default: () => "",
  })
  to!: string | Location;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) loading!: boolean;

  protected get showOnlyIcon(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }
}
