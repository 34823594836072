import { IApiError } from "@/errors/IApiError";
import IModelStatus from "@/models/apiTypes/IModelStatus";
import { ErrorCode } from "@/enums/ErrorCode";

export class MultiApiError extends Error implements IApiError {
  private readonly _errors: IApiError[];
  private _type: string = "multi-api-error";
  private _validationErrors: Map<string, string[]> = new Map<
    string,
    string[]
  >();
  private _reasons: string[] = [];
  private _state: IModelStatus | undefined = undefined;

  code = ErrorCode.MULTI_ERROR;

  constructor(errors: IApiError[]) {
    super("");
    this._errors = errors;
    this._errors.forEach((value) => {
      this._reasons.push(...value.reasons);
    });
  }

  get errors(): IApiError[] {
    return this._errors;
  }

  get type(): string {
    return this._type;
  }

  get validationErrors(): Map<string, string[]> {
    return this._validationErrors;
  }

  get reasons(): string[] {
    return this._reasons;
  }

  get state(): IModelStatus | undefined {
    return this._state;
  }
}
