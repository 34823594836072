import { NotificationType } from "@/events/NotificationType";
import Link from "@/models/vos/Link";
import { TranslateResult } from "vue-i18n";

export class NotificationMessage {
  private readonly _event: NotificationType;
  private readonly _links: Link[] = [];
  private readonly _message: string;
  private readonly _details: string[];

  constructor(
    event: NotificationType,
    message: string | TranslateResult,
    details: string[] = [],
    links: Link[] = []
  ) {
    this._links = links;
    this._event = event;
    this._message = message.toString();
    this._details = details;
  }

  get event(): NotificationType {
    return this._event;
  }

  get message(): string {
    return this._message;
  }

  get details(): string[] {
    return this._details;
  }

  get links(): Link[] {
    return this._links;
  }
}
