

















































import { Component, Prop, PropSync } from "vue-property-decorator";
import Vue from "vue";
import ButtonVO from "@/models/vos/ButtonVO";
import ButtonMenu from "@/views/components/common/buttons/ButtonMenu.vue";
import PageButton from "@/views/components/common/buttons/page/PageButton.vue";
import SearchField from "@/views/components/common/SearchField.vue";
import { mixins } from "vue-class-component";
import SearchModel from "@/views/mixins/SearchModel.vue";

@Component({
  components: { SearchField, PageButton, ButtonMenu },
})
export default class IToolbar extends mixins(SearchModel) {
  @Prop({ default: () => [] }) buttonVos!: ButtonVO[];
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) tooltip!: string;
  @Prop({ default: false }) showExpandIcon!: boolean;
  @Prop({ default: "" }) expandIcon!: string;
  @Prop({ default: false }) showSearch!: boolean;
  @Prop({ default: false }) dense!: boolean;
  @Prop({ default: false }) short!: boolean;
  @Prop({ default: true }) flat!: boolean;

  protected onButtonClicked(eventName: string) {
    this.$emit("button-event", eventName);
    this.$emit(eventName);
  }

  protected get visibleButtons(): ButtonVO[] {
    const index: number = this.$vuetify.breakpoint.mdAndUp ? 3 : 0;

    return index ? this.buttonVos.slice(0, index) : [];
  }

  protected get showMenu(): boolean {
    return this.visibleButtons.length < this.buttonVos.length;
  }
}
