



































































import Vue from "vue";
import Component from "vue-class-component";
import { Model, Prop, Watch } from "vue-property-decorator";

@Component
export default class SelectAllWithSearch extends Vue {
  @Model("change", { type: [Array, Object] }) readonly value!: any[] | any;
  @Prop({ default: () => [] }) errorMessages!: string[];
  @Prop({ default: "" }) label!: string;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: false }) returnObject!: boolean;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: () => [] }) items!: any[];
  @Prop({ default: () => "", type: [String, Object] }) itemValue!:
    | string
    | any[]
    | { (): string };
  @Prop({ default: () => "", type: [String, Object, Function, Array] })
  itemText!: string | any[] | { (): string };
  @Prop({ default: false }) clearable!: boolean;
  @Prop({ default: false }) multiple!: boolean;
  @Prop({ default: "" }) hint!: string;
  @Prop({ default: false }) persistentHint!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) chips!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) dense!: boolean;

  private search: string = "";

  get isMultiSelect() {
    return Array.isArray(this.value) && this.multiple;
  }

  get allSelected() {
    return this.isMultiSelect
      ? this.selectedItems.length === this.filteredItems.length
      : false;
  }

  get someSelected() {
    return (
      this.isMultiSelect && !this.allSelected && this.selectedItems.length > 0
    );
  }

  get filteredItems() {
    let filteredItems = this.items;

    if (this.search) {
      let searchToLower = this.search.toLowerCase();
      filteredItems = this.items.filter((item, index) => {
        let contained = false;
        if (typeof this.itemText === "string") {
          const propertyValue = item[this.itemText];
          if (propertyValue) {
            contained = propertyValue
              .toString()
              .toLowerCase()
              .includes(this.search.toLowerCase());
          }
        } else if (Array.isArray(this.itemText)) {
          for (const attribute of this.itemText) {
            let propertyValue = item[attribute];
            if (propertyValue) {
              contained =
                contained ||
                propertyValue
                  .toString()
                  .toLowerCase()
                  .includes(this.search.toLowerCase());
            }
          }
        } else {
          contained = item.toString().toLowerCase().includes(searchToLower);
        }

        return contained;
      });
    }

    return filteredItems;
  }

  get selectedItems() {
    return this.value;
  }

  set selectedItems(selectedItems: any[] | any) {
    this.$emit("change", selectedItems);
  }

  get icon() {
    let icon = "mdi-checkbox-blank-outline";

    if (this.allSelected) {
      icon = "mdi-close-box";
    } else if (this.someSelected) {
      icon = "mdi-minus-box";
    }
    return icon;
  }

  toggleSelection() {
    this.$nextTick(() => {
      if (this.allSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.filteredItems.slice();
      }
    });
  }
  @Watch("items", { immediate: true })
  protected onItemsChanged() {
    if (this.items.length === 1) {
      this.selectedItems = this.multiple ? this.items : this.items[0];
    }
  }
}
