






import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class DialogCancelButton extends Vue {
  @Prop({ default: false }) loading!: boolean;

  protected get buttonText() {
    return this.$t("actions.cancel");
  }
}
