import Vue from "vue";
import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { container } from "@/inversify.config";
import TYPES from "@/types";
import { INotificationService } from "@/services/INotificationService";

// Install the vue plugin
Vue.use(VueApollo);
const apolloClient = container.get<ApolloClient<any>>(TYPES.ApolloClient);
const wsClient = container.get<SubscriptionClient>(TYPES.SubscriptionClient);
const notificationService: INotificationService =
  container.get<INotificationService>(TYPES.INotificationService);

// Call this in the Vue app file
function createProvider(
  apolloClient: ApolloClient<any>,
  wsClient: SubscriptionClient,
  notificationService: INotificationService
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
      },
    },
    errorHandler(error) {
      // tslint:disable-next-line no-console
      notificationService.reportError(error);
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });

  return apolloProvider;
}

export const apolloProvider = createProvider(
  apolloClient,
  wsClient,
  notificationService
);
