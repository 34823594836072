import _ from "lodash";
import HasId from "@/models/vos/HasId";
import ErrorBag from "@/utils/ErrorBag";

export interface Model extends HasId {
  created_at?: string;
  updated_at?: string;
  errorBag?: ErrorBag;
  errors?: string[];
}

export const INVALID_ID: string = "-1";

export function removeModelById<T extends HasId>(sourceArray: T[], id: string) {
  const index = sourceArray.findIndex((value) => value.id == id);
  if (index > -1) {
    sourceArray.splice(index, 1);
  }
}

export function addOrReplaceModelById<T extends HasId>(
  sourceArray: T[],
  model: T
) {
  const oldModel = findModelById<T>(sourceArray, model.id);
  const index = sourceArray.findIndex((value) => value.id == model.id);
  if (index > -1) {
    sourceArray.splice(index, 1, model);
  } else {
    sourceArray.push(model);
  }
}

export function addOrUpdateModelById<T extends HasId>(
  sourceArray: T[],
  model: T
) {
  const oldModel = findModelById<T>(sourceArray, model.id);
  //const index = sourceArray.findIndex(value => value.id === model.id);
  if (oldModel) {
    _.merge(oldModel, model);
  } else {
    sourceArray.push(model);
  }
}

export function findModelById<T extends HasId>(
  sourceArray: T[],
  id: string
): T | undefined {
  return sourceArray.find((model) => model.id == id);
}

export function findIndexById<T extends HasId>(
  sourceArray: T[],
  id: string
): number {
  return sourceArray.findIndex((model) => model.id == id);
}
