











import Vue from "vue";
import Component from "vue-class-component";
import TableItemButton from "@/views/components/common/buttons/tableItem/TableItemButton.vue";
import { Prop } from "vue-property-decorator";
import { Location } from "vue-router";
import i18n from "@/i18n";

@Component({
  components: { TableItemButton },
})
export default class TableItemShowButton extends Vue {
  @Prop({ required: true }) to!: Location | string;
  @Prop({ default: i18n.t("actions.show") }) text!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop() target!: string;
}
