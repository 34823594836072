

































import Component, { mixins } from "vue-class-component";
import { HumanFriendlyError } from "@/models/HumanFriendlyError";
import TYPES from "@/types";
import { lazyInject } from "@/inversify.config";
import { AbstractApiError } from "@/errors/AbstractApiError";
import IDialog from "@/views/components/common/dialog/IDialog.vue";
import VisibleVModel from "@/views/mixins/VisibleVModel.vue";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import { getModule } from "vuex-module-decorators";
import PermissionsModule from "@/modules/PermissionsModule";

@Component({
  components: { IDialog },
})
export default class PasswordConfirmation extends mixins(VisibleVModel) {
  @lazyInject(TYPES.IGraphQLBackendApi)
  protected backendApi!: IGraphQLBackendApi;
  protected permissionModule = getModule(PermissionsModule);
  protected errors: HumanFriendlyError = new HumanFriendlyError();
  protected loading = false;
  protected password: string = "";

  protected get title(): string {
    return this.$t("Password Confirmation").toString();
  }

  protected reset() {
    this.password = "";
  }

  protected confirmPassword() {
    this.loading = true;
    this.errors.clear();

    this.backendApi
      .confirmPassword(this.password)
      .then((success) => {
        this.permissionModule.setPasswordConfirmationRequired(false);
      })
      .catch((error) => {
        this.errors.apiError = error.response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  protected cancel() {
    this.errors.clear();
    this.$emit("canceled");
  }
}
