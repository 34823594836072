
















import Vue from "vue";
import Component from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";

@Component
export default class PasswordField extends Vue {
  @Model("change") readonly value!: string;
  @Prop({ default: "password" }) readonly name!: string;
  @Prop({ default: () => [] }) errorMessages!: string[];
  @Prop({ default: "" }) label!: string;
  @Prop({ default: false }) autofocus!: boolean;
  protected show: boolean = false;

  protected set password(password: string) {
    this.$emit("change", password);
  }

  protected get password(): string {
    return this.value;
  }
}
