






























import Component, { mixins } from "vue-class-component";
import SearchModel from "@/views/mixins/SearchModel.vue";

@Component
export default class SearchField extends mixins(SearchModel) {
  protected debounceHandle: any = null;
  protected showSearchField: boolean = false;
  protected showSearchIcon: boolean = true;
  protected fieldHasFocus: boolean = false;

  protected onInputChanged(search: string) {
    // if (this.debounceHandle) clearTimeout(this.debounceHandle);
    // this.debounceHandle = setTimeout(() => {
    this.$emit("input", search);
    // }, 1000);
  }

  protected onIconClicked() {
    this.showField = true;
    this.fieldHasFocus = true;
    this.$nextTick(() => {
      //@ts-ignore
      this.$refs.searchField.focus();
    });
  }

  protected onSearchFocusLost() {
    this.showField = false;
    this.fieldHasFocus = false;
  }

  protected set showField(show: boolean) {
    this.showSearchIcon = !show;
    this.showSearchField = show;
  }

  protected get showField() {
    return this.showSearchField;
  }

  protected onMouseLeftField() {
    //@ts-ignore
    if (!this.fieldHasFocus) {
      this.showField = false;
    }
  }
}
