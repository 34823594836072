const TYPES = {
  IAuthenticationService: "IAuthenticationService",
  IGraphQLBackendApi: "IGraphQLBackendApi",
  AxiosInstance: "AxiosInstance",
  INotificationService: "INotificationService",
  IUIStateService: "IUIStateService",
  IClassService: "IClassService",
  ISchoolYearService: "ISchoolYearService",
  ISubjectService: "ISubjectService",
  IModelFactory: "IModelFactory",
  IStudentDataService: "IStudentDataService",
  ITeacherDataService: "ITeacherDataService",
  IUserDataService: "IUserDataService",
  ISkillDataService: "ISkillDataService",
  IActivityDataService: "IActivityDataService",
  ApolloClient: "ApolloClient",
  SubscriptionClient: "SubscriptionClient",
  IClassSubjectPartReportDataService: "IClassSubjectPartReportDataService",
  IAbsenceDataService: "IAbsenceDataService",
  IStudentReportDataService: "IStudentReportDataService",
  IImportDataService: "IImportDataService",
  ISchoolSubjectDataGroupService: "ISchoolSubjectGroupDataService",
};
export default TYPES;
