






import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class BooleanIcon extends Vue {
  @Prop({ default: false }) value!: boolean;

  get color(): string {
    return this.value ? "green" : "red";
  }

  get icon(): string {
    return this.value ? "mdi-check-circle-outline" : "mdi-minus-circle-outline";
  }
}
