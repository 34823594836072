import IAuthenticationService from "@/services/IAuthenticationService";
import { inject, injectable } from "inversify";
import TYPES from "@/types";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";

@injectable()
export default class AuthenticationService implements IAuthenticationService {
  constructor(
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {}

  resetPassword(email: string): Promise<boolean> {
    return Promise.resolve(false);
  }
}
