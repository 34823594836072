import { Observable, Subject } from "rxjs";
import { injectable } from "inversify";
import { NotificationMessage } from "@/events/NotificationMessage";
import { INotificationService } from "@/services/INotificationService";
import { IApiError } from "@/errors/IApiError";
import { AbstractApiError } from "@/errors/AbstractApiError";
import { NotificationType } from "@/events/NotificationType";
import i18n from "@/i18n";
import { TranslateResult } from "vue-i18n";

@injectable()
export class NotificationService implements INotificationService {
  newNotification: Observable<NotificationMessage>;
  private _notification: Subject<NotificationMessage>;

  constructor() {
    this._notification = new Subject();
    this.newNotification = this._notification.asObservable();
  }

  sendNotification(notification: NotificationMessage) {
    this._notification.next(notification);
  }

  reportError(error: Error | IApiError): void {
    let notification: NotificationMessage;
    if (error instanceof AbstractApiError) {
      notification = new NotificationMessage(
        NotificationType.Error,
        error.message,
        error.reasons
      );
    } else {
      notification = new NotificationMessage(
        NotificationType.Error,
        process.env.NODE_ENV === "production"
          ? i18n.t("errors.unexpected").toString()
          : error.message
      );
    }
    this._notification.next(notification);
  }

  reportDeleteSuccess(modelName: string): void {
    this._notification.next(
      new NotificationMessage(
        NotificationType.Success,
        i18n.t("delete.success", { model: modelName }).toString()
      )
    );
  }

  reportSuccess(message: string | TranslateResult): void {
    this._notification.next(
      new NotificationMessage(NotificationType.Success, message.toString())
    );
  }

  reportSaveSuccess(type: string): void {
    this.reportSuccess(
      i18n
        .t("saveSuccess", {
          type,
        })
        .toString()
    );
  }
}
