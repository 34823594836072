






























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { AttributeVO } from "@/models/vos/AttributeVO";
import BooleanIcon from "@/views/components/common/BooleanIcon.vue";
import TableItemShowButton from "@/views/components/common/buttons/tableItem/TableItemShowButton.vue";

@Component({ components: { TableItemShowButton, BooleanIcon } })
export default class PropertyArea extends Vue {
  @Prop() attributes!: AttributeVO[];
}
