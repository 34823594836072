export function getCookie(cname: string) {
  const token = RegExp(`${cname}[^;]+`).exec(document.cookie);
  return decodeURIComponent(
    token ? token.toString().replace(/^[^=]+./, "") : ""
  );
}

export function deleteCookie(cname: string) {
  if (getCookie(cname)) {
    document.cookie = cname + "=" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}
