import { ISchoolYearService } from "@/services/ISchoolYearService";
import { SchoolYear } from "@/models/SchoolYear";
import TYPES from "@/types";
import { inject, injectable } from "inversify";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import { INVALID_ID } from "@/models/Model";
import { SoftSkill } from "@/models/SoftSkill";
import getSchoolYear from "@/graphql/schoolYears/getSchoolYear.graphql";
import allSchoolYears from "@/graphql/schoolYears/allSchoolYears.graphql";
import softSkillsBySchoolYear from "@/graphql/softSkills/softSkillsBySchoolYear.graphql";
import createSchoolYear from "@/graphql/schoolYears/createSchoolYear.graphql";
import updateSchoolYear from "@/graphql/schoolYears/updateSchoolYear.graphql";
import deleteSchoolYear from "@/graphql/schoolYears/deleteSchoolYear.graphql";
import releaseTerm from "@/graphql/schoolYears/releaseTerm.graphql";

@injectable()
export class SchoolYearService implements ISchoolYearService {
  constructor(
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {}

  allSchoolYears(): Promise<SchoolYear[]> {
    return this.backendApi.apolloQuery<SchoolYear[]>(
      "schoolYears",
      allSchoolYears
    );
  }

  getSchoolYear(id: string): Promise<SchoolYear> {
    return this.backendApi.apolloQuery<SchoolYear>(
      "schoolYear",
      getSchoolYear,
      { id }
    );
  }

  updateSchoolYear(schoolYear: SchoolYear): Promise<SchoolYear> {
    return this.backendApi.apolloMutation<SchoolYear>(
      "updateSchoolYear",
      updateSchoolYear,
      {
        id: schoolYear.id,
        year: schoolYear.year,
        start: schoolYear.start,
        end: schoolYear.end,
        start_second_term: schoolYear.start_second_term,
      }
    );
  }

  deleteSchoolYear(schoolYear: SchoolYear): Promise<boolean> {
    return this.backendApi
      .apolloMutation("deleteSchoolYear", deleteSchoolYear, {
        id: schoolYear.id,
      })
      .then(() => {
        return true;
      });
  }

  getSoftSkillsBySchoolYearId(id: string): Promise<SoftSkill[]> {
    return this.backendApi
      .apolloQuery<any>("schoolYear", softSkillsBySchoolYear, {
        school_year_id: id,
      })
      .then((year) => {
        return year.softSkills;
      });
  }

  createSchoolYear(
    year: number,
    start: string,
    end: string,
    startSecondTerm: string,
    copyLastYear: boolean
  ): Promise<SchoolYear> {
    return this.backendApi.apolloMutation<SchoolYear>(
      "createSchoolYear",
      createSchoolYear,
      {
        year,
        start,
        end,
        startSecondTerm,
        copyLastYear,
      }
    );
  }

  getSchoolYearName(schoolYear: SchoolYear) {
    return schoolYear.year ? `${schoolYear.year}/${schoolYear.year + 1}` : "";
  }

  releaseTerm(schoolYear: SchoolYear, term: number): Promise<SchoolYear> {
    return this.backendApi.apolloMutation<SchoolYear>(
      "releaseTerm",
      releaseTerm,
      {
        school_year_id: schoolYear.id,
        term,
      }
    );
  }
}
