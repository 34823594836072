import { container } from "@/inversify.config";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "@/style/main.scss";
import vuetify from "@/plugins/vuetify";
import { apolloProvider } from "@/plugins/vue-apollo";
import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import PermissionsModule from "@/modules/PermissionsModule";
import { UserInfo } from "@/models/UserInfo";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import TYPES from "@/types";
import { RoutePlugin } from "@/plugins/routesPlugin";
import VueMeta from "vue-meta";
import { deleteCookie, getCookie } from "@/helpers/CookieExtractor";
import "./registerServiceWorker";
import { AxiosInstance } from "axios";

const tmp = container;

Vue.config.productionTip = false;
Vue.use(RoutePlugin);
Vue.use(VueMeta);

// Register the router hooks with their names
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);
const permissionModule = getModule(PermissionsModule);
const backendApi: IGraphQLBackendApi = container.get<IGraphQLBackendApi>(
  TYPES.IGraphQLBackendApi
);

container.get<AxiosInstance>(TYPES.AxiosInstance).interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 423) {
      permissionModule.setPasswordConfirmationRequired(true);
    }
    return Promise.reject(error);
  }
);

//receive app version
backendApi
  .init()
  .then((response) => {
    return backendApi.getName();
  })
  .then((name) => {
    return backendApi.confirmLogin("");
  })
  .then((userInfo: UserInfo) => {
    const impersonateCookie = getCookie("impersonate");
    if (impersonateCookie) {
      permissionModule.setImpersonationActive(true);
    } else {
      permissionModule.setImpersonationActive(false);
      deleteCookie("impersonate");
    }

    permissionModule.setActiveUserInfo(userInfo);
    return permissionModule.requestCurrentSchoolYear();
  })
  .catch((error) => {
    permissionModule.setMaintenanceMode(error.response?.status == 503 ?? false);

    backendApi.reset();
  })
  .finally(() => {
    new Vue({
      router,
      store,
      i18n,
      vuetify,
      apolloProvider,
      render: (h) => h(App),
    }).$mount("#app");
  });
