












































import Component, { mixins } from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";
import ErrorAlert from "@/views/components/common/ErrorAlert.vue";
import TableRefreshButton from "@/views/components/common/TableRefreshButton.vue";
import SelectAllWithSearch from "@/views/components/common/select/SelectAllWithSearch.vue";
import DialogCancelButton from "@/views/components/common/buttons/dialog/DialogCancelButton.vue";
import DialogSaveButton from "@/views/components/common/buttons/dialog/DialogSaveButton.vue";
import { HumanFriendlyError } from "@/models/HumanFriendlyError";
import Vue from "vue";
import VisibleVModel from "@/views/mixins/VisibleVModel.vue";

@Component({
  components: {
    DialogSaveButton,
    DialogCancelButton,
    SelectAllWithSearch,
    ErrorAlert,
    TableRefreshButton,
  },
})
export default class IDialog extends mixins(VisibleVModel) {
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: () => "" }) title!: string;
  @Prop({ default: () => "" }) subTitle!: string;
  @Prop({ default: 600 }) maxWidth!: number;
  @Prop({ default: () => null }) errors!: HumanFriendlyError;
  @Prop({ default: false }) saveDisabled!: boolean;
  @Prop({ default: false }) persistent!: boolean;
  @Prop({ default: false }) no_actions!: boolean;

  protected get fullscreen(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }
}
