import { GraphQLErrorPayload } from "@/models/GraphQLErrorPayload";
import { IApiError } from "@/errors/IApiError";
import { AbstractApiError } from "@/errors/AbstractApiError";
import { SourceLocation } from "graphql";
import IModelStatus from "@/models/apiTypes/IModelStatus";
import State from "@/models/vos/ModelStatus";
import { ErrorCode } from "@/enums/ErrorCode";

export class GraphQLError extends AbstractApiError implements IApiError {
  private readonly _error: GraphQLErrorPayload;

  constructor(error: GraphQLErrorPayload) {
    super(error.message, "GraphQLErrorPayload");
    this._error = error;
  }

  get debugMessage(): string {
    const debugMessage = this._error.debugMessage;
    return debugMessage ? debugMessage : "";
  }

  get extensions():
    | { [key: string]: any; validation?: { [index: string]: string[] } }
    | undefined {
    return this._error.extensions;
  }

  get locations(): ReadonlyArray<SourceLocation> | undefined {
    return this._error.locations;
  }

  get path(): ReadonlyArray<string | number> | undefined {
    return this._error.path;
  }

  get reasons(): string[] {
    return this._error.extensions && this._error.extensions.reasons
      ? this._error.extensions.reasons
      : [];
  }

  get type(): string {
    return this._error.extensions && this._error.extensions.category
      ? this._error.extensions.category
      : "";
  }

  get validationErrors(): Map<string, string[]> {
    const errors = new Map<string, string[]>();
    if (this._error.extensions && this._error.extensions.validation) {
      const validation = this._error.extensions.validation;

      for (const fieldName in validation) {
        if (validation.hasOwnProperty(fieldName)) {
          errors.set(fieldName, validation[fieldName]);
        }
      }
    }

    return errors;
  }

  get state(): IModelStatus | undefined {
    let state = undefined;

    if (this._error.extensions && this._error.extensions.state) {
      state = new State(this._error.extensions.state.errors);
    }

    return state;
  }

  get code(): ErrorCode {
    return this._error.extensions && this._error.extensions.code
      ? this._error.extensions.code
      : ErrorCode.GENERIC;
  }
}
