








import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class NotFoundComponent extends Vue {
  @Prop({ default: "Oooops... 404" }) message!: string;
}
