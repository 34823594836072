













































import Component, { mixins } from "vue-class-component";
import PageConfirmButton from "@/views/components/common/PageConfirmButton.vue";
import { HumanFriendlyError } from "@/models/HumanFriendlyError";
import TYPES from "@/types";
import { lazyInject } from "@/inversify.config";
import ErrorAlert from "@/views/components/common/ErrorAlert.vue";
import IUserDataService from "@/services/IUserDataService";
import DialogSaveButton from "@/views/components/common/buttons/dialog/DialogSaveButton.vue";
import PasswordField from "@/views/components/common/buttons/PasswordField.vue";
import IDialog from "@/views/components/common/dialog/IDialog.vue";
import VisibleVModel from "@/views/mixins/VisibleVModel.vue";
import { IApiError } from "@/errors/IApiError";

@Component({
  components: {
    IDialog,
    PasswordField,
    DialogSaveButton,
    ErrorAlert,
    PageConfirmButton,
  },
})
export default class ChangePassword extends mixins(VisibleVModel) {
  @lazyInject(TYPES.IUserDataService)
  userService!: IUserDataService;

  protected password: string = "";
  protected previousPassword: string = "";
  protected password_confirm: string = "";
  protected loading: boolean = false;
  protected errors: HumanFriendlyError = new HumanFriendlyError();

  protected cancel() {
    this.$emit("canceled");
  }

  protected reset() {
    this.password = "";
    this.previousPassword = "";
    this.password_confirm = "";
    this.errors.clear();
  }

  protected changePassword() {
    this.errors.clear();
    this.loading = true;
    this.userService
      .changePassword(
        this.previousPassword,
        this.password,
        this.password_confirm
      )
      .then(() => {
        this.$notificationService.reportSuccess(
          this.$t("changePasswordSuccess")
        );
        this.$emit("saved");
        return true;
      })
      .catch((error: IApiError) => {
        this.errors = new HumanFriendlyError(error);

        this.$notificationService.reportError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
