import IRelatedModel from "@/models/apiTypes/IRelatedModel";
import IState from "@/models/apiTypes/IState";

export default class State implements IState {
  private readonly _code: number;
  private readonly _relatedModels: IRelatedModel[];
  private readonly _message: string;

  constructor(code: number, relatedModels: IRelatedModel[], message = "") {
    this._code = code;
    this._relatedModels = relatedModels;
    this._message = message;
  }

  get code(): number {
    return this._code;
  }

  get relatedModels(): IRelatedModel[] {
    return this._relatedModels;
  }

  get message(): string {
    return this._message;
  }
}
