import { IApiError } from "@/errors/IApiError";
import IModelStatus from "@/models/apiTypes/IModelStatus";
import { ErrorCode } from "@/enums/ErrorCode";

export abstract class AbstractApiError implements IApiError {
  message: string;
  name: string;

  protected constructor(message: string, name: string) {
    this.message = message;
    this.name = name;
  }

  abstract get validationErrors(): Map<string, string[]>;

  abstract get reasons(): string[];

  abstract get type(): string;

  abstract get state(): IModelStatus | undefined;
  abstract get code(): ErrorCode;
}
