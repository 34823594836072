
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component
export default class HasExpandableTitle extends Vue {
  @Prop({ default: false }) expandableTitle!: boolean;
  @Prop({ default: true }) expandOnStart!: boolean;
}
