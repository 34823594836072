






























































import Vue from "vue";
import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import PermissionsModule from "@/modules/PermissionsModule";
import { RouteName } from "@/enums/RouteName";
import { IApiError } from "@/errors/IApiError";
import { ErrorType } from "@/enums/ErrorType";
import { RawLocation } from "vue-router";
import PasswordField from "@/views/components/common/buttons/PasswordField.vue";
import { UserInfo } from "@/models/UserInfo";
import TwoFactorChallenge from "@/views/components/twoFactorAuth/TwoFactorChallenge.vue";

@Component({
  components: { TwoFactorChallenge, PasswordField },
  metaInfo() {
    return {
      title: this.$t("metaInfo.login.title").toString(),
    };
  },
})
export default class Login extends Vue {
  private loading: boolean = false;
  private form: { email: string; password: string } = {
    email: "",
    password: "",
  };
  private formErrors: { email: string[]; password: string[] } = {
    email: [],
    password: [],
  };

  protected showTwoFactorChallenge: boolean = false;
  private permissionsModule = getModule(PermissionsModule);

  protected redirectLocation: RawLocation = { name: RouteName.HOME };

  protected login(event: Event) {
    this.loading = true;
    this.permissionsModule
      .login({ ...this.form })
      .then((success) => {
        this.redirectLocation =
          typeof this.$route.query?.redirect === "string"
            ? this.$route.query.redirect
            : { name: RouteName.HOME };
        if (this.permissionsModule.twoFactorChallengeActivated) {
          //proceed with 2fa
          this.showTwoFactorChallenge = true;
        } else {
          return this.redirect();
        }
      })
      .catch((reason: IApiError) => {
        this.formErrors.email = [this.$t("invalidCredentials").toString()];
      })
      .finally(() => {
        this.loading = false;
      });
  }

  protected onTwoFactorChallengeCompleted(userInfo: UserInfo) {
    this.permissionsModule.setActiveUserInfo(userInfo);
    return this.permissionsModule.requestCurrentSchoolYear().then(() => {
      return this.redirect();
    });
  }

  protected redirect() {
    return this.$router.push(this.redirectLocation);
  }

  mounted() {
    this.$nextTick((this.$refs.email as HTMLInputElement).focus);
  }
}
