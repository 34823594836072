

































































































import Component, { mixins } from "vue-class-component";
import { HumanFriendlyError } from "@/models/HumanFriendlyError";
import TYPES from "@/types";
import { Watch } from "vue-property-decorator";
import { lazyInject } from "@/inversify.config";
import { AbstractApiError } from "@/errors/AbstractApiError";
import ErrorAlert from "@/views/components/common/ErrorAlert.vue";
import IDialog from "@/views/components/common/dialog/IDialog.vue";
import VisibleVModel from "@/views/mixins/VisibleVModel.vue";
import StepperHeader from "@/models/vos/StepperHeader";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import PermissionsModule from "@/modules/PermissionsModule";
import { getModule } from "vuex-module-decorators";
import { TwoFactorAuthQRCode } from "@/models/TwoFactorAuthQRCode";

@Component({
  components: { IDialog, ErrorAlert },
})
export default class TwoFactorAuthEnable extends mixins(VisibleVModel) {
  @lazyInject(TYPES.IGraphQLBackendApi)
  protected backendApi!: IGraphQLBackendApi;
  protected permissionModule: PermissionsModule = getModule(PermissionsModule);
  protected errors: HumanFriendlyError = new HumanFriendlyError();
  protected loading = false;
  protected qrCode: string = "";
  protected confirmCode: string = "";
  protected recoveryCodes: string[] = [];

  protected step: number = 1;

  protected get steps() {
    return [
      new StepperHeader(this.$t("actions.activate"), 1),
      new StepperHeader(this.$tc("recoveryCode", 2), 2),
    ];
  }

  @Watch("visible")
  protected onVisibleChanged(isVisible: boolean) {
    if (!isVisible) {
      this.reset();
    } else {
      this.enableTwoFactorAuth();
    }
  }

  protected get enableConfirm() {
    return this.qrCode && this.confirmCode.length > 0;
  }

  protected get title(): string {
    return this.$t("enableTwoFactorAuth").toString();
  }

  protected reset() {
    this.qrCode = "";
    this.confirmCode = "";
  }

  protected enableTwoFactorAuth() {
    this.loading = true;
    this.errors.clear();

    this.backendApi
      .enableTwoFactorAuth()
      .then((qrCode: TwoFactorAuthQRCode) => {
        this.qrCode = qrCode.svg;
      })
      .catch((error: Error) => {
        if (error instanceof AbstractApiError) {
          this.errors.apiError = error;
        }
        this.$notificationService.reportError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  protected confirmTwoFactorAuth() {
    this.loading = true;
    this.errors.clear();

    this.backendApi
      .confirmTwoFactorAuth(this.confirmCode)
      .then((recoveryCodes: string[]) => {
        this.step = 2;
        this.recoveryCodes = recoveryCodes;
        this.permissionModule.setTwoFactorAuthEnabled(true);
      })
      .catch((error) => {
        this.errors.apiError = error.response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  protected cancel() {
    this.errors.clear();
    this.$emit("canceled");
  }
}
