import { IApiError } from "@/errors/IApiError";
import ErrorBag from "./ErrorBag";

export class FormErrorBag implements ErrorBag {
  [index: string]: string[];

  static fromApiError(apiError: IApiError): FormErrorBag {
    return new FormErrorBag(apiError);
  }

  constructor(apiError: IApiError) {
    apiError.validationErrors.forEach((value, key) => {
      this[key] = value;
    });
  }
}
