





















































import Vue from "vue";
import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import PermissionsModule from "@/modules/PermissionsModule";
import { RouteName } from "@/enums/RouteName";
import { IApiError } from "@/errors/IApiError";
import { ErrorType } from "@/enums/ErrorType";
import PageConfirmButton from "@/views/components/common/PageConfirmButton.vue";
import { HumanFriendlyError } from "@/models/HumanFriendlyError";
import TYPES from "@/types";
import IAuthenticationService from "@/services/IAuthenticationService";
import { lazyInject } from "@/inversify.config";
import ErrorAlert from "@/views/components/common/ErrorAlert.vue";
import IUserDataService from "@/services/IUserDataService";
import IAppBar from "@/views/components/common/toolbar/IAppBar.vue";
import ButtonVO from "@/models/vos/ButtonVO";
import PropertyArea from "@/views/components/common/PropertyArea.vue";
import { AttributeVO } from "@/models/vos/AttributeVO";
import TwoFactorAuthEnable from "@/views/components/twoFactorAuth/TwoFactorAuthEnable.vue";
import IConfirmDialog from "@/views/components/common/dialog/IConfirmDialog.vue";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import IToolbar from "@/views/components/common/toolbar/IToolbar.vue";
import { Watch } from "vue-property-decorator";
import getAmountActiveSessions from "@/graphql/users/getAmountActiveSessions.graphql";

@Component({
  components: {
    IToolbar,
    IConfirmDialog,
    TwoFactorAuthEnable,
    PropertyArea,
    IAppBar,
    ErrorAlert,
    PageConfirmButton,
  },
  metaInfo() {
    return {
      title: this.$t("metaInfo.profile.title").toString(),
    };
  },
  apollo: {
    amountActiveSessions: {
      query: getAmountActiveSessions,
      update: (data) => data.amountMyActiveSessions,
    },
  },
})
export default class UserProfile extends Vue {
  @lazyInject(TYPES.IGraphQLBackendApi)
  backendApi!: IGraphQLBackendApi;
  protected loading: boolean = false;
  protected email: string = "";
  protected errors: HumanFriendlyError = new HumanFriendlyError();
  protected permissionModule: PermissionsModule = getModule(PermissionsModule);

  protected showEnable2FaDialog: boolean = false;
  protected showDisable2FaDialog: boolean = false;
  protected showRegenerateCodesDialog: boolean = false;
  protected recoveryCodes: string[] = [];
  protected loadingCodes: boolean = false;

  protected amountActiveSessions: number = 0;

  protected regenerateIsRunning: boolean = false;

  protected get title(): string {
    return this.$tc("userProfile", 1) + ": " + this.permissionModule.userName;
  }

  protected showRegenerateCodes() {
    this.showRegenerateCodesDialog = true;
  }

  protected get recoveryCodeButtons() {
    return [
      {
        icon: "mdi-eye-outline",
        event: "showCodes",
        text: this.$t("actions.show").toString(),
        loading: this.loadingCodes,
      },
      {
        color: "warning",
        icon: "mdi-refresh",
        event: "generateCodes",
        text: this.$t("Regenerate").toString(),
        loading: this.loadingCodes,
      },
    ];
  }

  protected get appBarButtons(): ButtonVO[] {
    const buttons: ButtonVO[] = [];
    if (!this.permissionModule.twoFactorAuthenticationEnabled) {
      buttons.push({
        color: "success",
        icon: "mdi-shield-lock-outline",
        event: "enable-2fa",
        text: this.$t("enableTwoFactorAuth").toString(),
      });
    } else {
      buttons.push({
        color: "error",
        icon: "mdi-shield-lock-open-outline",
        event: "disable-2fa",
        text: this.$t("disableTwoFactorAuth").toString(),
      });
    }

    return buttons;
  }

  @Watch("$store.state.permissions.passwordConfirmationRequired")
  protected onPasswordConfirmationChanged(
    isRequired: boolean,
    wasRequired: boolean
  ) {
    if (wasRequired && !isRequired && this.loadingCodes) {
      this.generateRecoveryCodes();
    }
  }

  protected loadRecoveryCodes() {
    this.loadingCodes = true;
    return this.backendApi
      .getRecoveryCodes()
      .then((codes) => {
        this.recoveryCodes = codes;
        this.loadingCodes = false;
      })
      .catch((error) => {
        this.errors.apiError = error.response.data;
      });
  }

  protected generateRecoveryCodes() {
    this.loadingCodes = true;
    return this.backendApi
      .regenerateRecoveryCodes()
      .then((codes) => {
        this.recoveryCodes = codes;
        this.loadingCodes = false;
        this.showRegenerateCodesDialog = false;
      })
      .catch((error) => {
        this.errors.apiError = error.response.data;
      });
  }

  protected get attributes(): AttributeVO[] {
    return [
      {
        title: this.$tc("userName", 1),
        value: this.permissionModule.userName,
      },
      {
        title: this.$tc("email", 1),
        value: this.permissionModule.email,
      },
      {
        title: this.$t("Two-Factor-Authentication"),
        value: this.permissionModule.twoFactorAuthenticationEnabled,
      },
      {
        title: this.$t("activeSessions"),
        value: this.amountActiveSessions,
      },
      {
        title: this.$t("recentFailedLoginAttempts"),
        value:
          this.permissionModule.activeUserInfo?.recent_failed_attempts.length ??
          0,
      },
    ];
  }

  protected disableTwoFactorAuth() {
    return this.backendApi.disableTwoFactorAuth().then((success) => {
      this.permissionModule.setTwoFactorAuthEnabled(false);
    });
  }
}
