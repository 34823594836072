import { TranslateResult } from "vue-i18n";

export default class StepperHeader {
  constructor(
    public title: string | TranslateResult,
    public step: number,
    public editable: boolean = false,
    public rules?: RuleFunction[],
    public completed?: boolean
  ) {}
}

type RuleFunction = () => boolean | string;
