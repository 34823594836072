import ISchoolSubjectGroupDataService from "@/services/ISchoolSubjectGroupDataService";
import { inject, injectable } from "inversify";
import TYPES from "@/types";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import SchoolSubjectGroup from "@/models/SchoolSubjectGroup";
import createSchoolSubjectGroup from "@/graphql/schoolSubjectGroups/createSchoolSubjectGroup.graphql";
import updateSchoolSubjectGroup from "@/graphql/schoolSubjectGroups/updateSchoolSubjectGroup.graphql";
import deleteSchoolSubjectGroup from "@/graphql/schoolSubjectGroups/deleteSchoolSubjectGroup.graphql";

@injectable()
export default class SchoolSubjectGroupDataService
  implements ISchoolSubjectGroupDataService
{
  constructor(
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {}

  createSchoolSubjectGroup(
    classLevelId: string,
    parentSchoolSubjectId: string,
    subSchoolSubjectIds: string[]
  ): Promise<SchoolSubjectGroup> {
    return this.backendApi.apolloMutation<SchoolSubjectGroup>(
      "createSchoolSubjectGroup",
      createSchoolSubjectGroup,
      {
        class_level_id: classLevelId,
        parent_school_subject_id: parentSchoolSubjectId,
        sub_school_subject_ids: subSchoolSubjectIds,
      }
    );
  }

  deleteSchoolSubjectGroup(group: SchoolSubjectGroup): Promise<boolean> {
    return this.backendApi
      .apolloMutation("deleteSchoolSubjectGroup", deleteSchoolSubjectGroup, {
        id: group.id,
      })
      .then(() => {
        return true;
      });
  }

  updateGroup(
    schoolSubjectGroup: SchoolSubjectGroup,
    subSchoolSubjectIds: string[]
  ): Promise<SchoolSubjectGroup> {
    return this.backendApi.apolloMutation<SchoolSubjectGroup>(
      "updateSchoolSubjectGroup",
      updateSchoolSubjectGroup,
      {
        id: schoolSubjectGroup.id,

        sub_school_subject_ids: subSchoolSubjectIds,
      }
    );
  }
}
