

















































































































import Vue from "vue";
import Component from "vue-class-component";
import Sidebar from "@/views/components/common/Sidebar.vue";
import TYPES from "@/types";
import { INotificationService } from "@/services/INotificationService";
import { lazyInject } from "./inversify.config";
import { NotificationMessage } from "@/events/NotificationMessage";
import PermissionsModule from "@/modules/PermissionsModule";
import { getModule } from "vuex-module-decorators";
import PageButton from "@/views/components/common/buttons/page/PageButton.vue";
import { RouteName } from "@/enums/RouteName";
import BasicButton from "@/views/components/common/buttons/BasicButton.vue";
import ChangePassword from "@/views/components/ChangePassword.vue";
import PasswordConfirmation from "@/views/components/twoFactorAuth/PasswordConfirmation.vue";
import IDialog from "@/views/components/common/dialog/IDialog.vue";

@Component({
  components: {
    IDialog,
    PasswordConfirmation,
    ChangePassword,
    BasicButton,
    PageButton,
    Sidebar,
  },
  metaInfo() {
    return {
      title: "IGS Schüler App",
      titleTemplate: "%s | IGS Schüler App",
    };
  },
})
export default class App extends Vue {
  @lazyInject(TYPES.INotificationService)
  private notificationService!: INotificationService;
  private notificationSubscription =
    this.notificationService.newNotification.subscribe((notification) => {
      this.displayNotification(notification);
    });
  private showSidebar: boolean = false;
  private showSnackbar: boolean = false;
  private latestNofication: NotificationMessage | null = null;
  private permissionModule: PermissionsModule = getModule(PermissionsModule);
  protected showChangePassword: boolean = false;

  protected get userName(): string {
    return this.isLoggedIn
      ? this.permissionModule.activeUserInfo?.name ?? ""
      : "";
  }

  protected get appName(): string {
    return this.permissionModule.appName;
  }

  protected get appVersion(): string {
    return this.permissionModule.appVersion;
  }

  protected get isLoggedIn(): boolean {
    return this.permissionModule.isLoggedIn;
  }

  protected logout() {
    this.permissionModule.logout().then(() => {
      this.$router.push({ name: RouteName.LOGIN });
    });
  }

  protected endImpersonation() {
    this.permissionModule.stopImpersonate().then(() => {
      location.reload();
    });
  }

  displayNotification(notfication: NotificationMessage) {
    this.latestNofication = notfication;
    this.showSnackbar = true;
  }

  destroy() {
    this.notificationSubscription.unsubscribe();
  }

  protected get isLoading(): boolean {
    return this.permissionModule.loading;
  }
  protected get maintenanceModeEnabled(): boolean {
    return this.permissionModule.maintenanceModeEnabled;
  }

  protected get passwordConfirmationRequired(): boolean {
    return this.permissionModule.passwordConfirmationRequired;
  }

  protected onPasswordChanged() {
    this.showChangePassword = false;
  }
}
