




























import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import SearchField from "@/views/components/common/SearchField.vue";
import SearchModel from "@/views/mixins/SearchModel.vue";
import ButtonVO from "@/models/vos/ButtonVO";
import IToolbar from "@/views/components/common/toolbar/IToolbar.vue";

@Component({
  components: { IToolbar, SearchField },
})
export default class ExpandWithToolbarFlat extends mixins(SearchModel) {
  @Prop({ default: true }) expandOnStart!: boolean;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: false }) showSearch!: boolean;
  @Prop({ default: () => [] }) buttonVos!: ButtonVO[];
  @Prop({ default: false }) forceExpand!: boolean;

  protected expanded: boolean = this.expandOnStart;

  protected get searchVisible(): boolean {
    return this.showSearch && this.expanded;
  }
  protected get showIcon() {
    return this.expanded ? "mdi-chevron-up" : "mdi-chevron-down";
  }

  protected onExpanded() {
    this.expanded = this.forceExpand || !this.expanded;
  }

  @Watch("expandOnStart")
  protected onExpandOnStartChanged(newValue: boolean, oldValue: boolean) {
    if (newValue !== oldValue) {
      this.expanded = newValue;
    }
  }
}
