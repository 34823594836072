import { IUiStateService } from "@/services/IUiStateService";
import { injectable, optional } from "inversify";
import { BehaviorSubject, Observable } from "rxjs";

@injectable()
export class UiStateService implements IUiStateService {
  sidebarCollapsed: Observable<boolean>;
  sidebarShown: Observable<boolean>;

  private _sidebarCollapseSubject: BehaviorSubject<boolean>;
  private _sidebarShownSubject: BehaviorSubject<boolean>;

  constructor(
    @optional() collapsed: boolean = true,
    @optional() hidden: boolean = false
  ) {
    this._sidebarCollapseSubject = new BehaviorSubject<boolean>(collapsed);
    this._sidebarShownSubject = new BehaviorSubject<boolean>(!hidden);
    this.sidebarCollapsed = this._sidebarCollapseSubject.asObservable();
    this.sidebarShown = this._sidebarShownSubject.asObservable();
  }

  collapseSidebar(collapse: boolean): void {
    this._sidebarCollapseSubject.next(collapse);
  }

  hideSidebar(hide: boolean): void {
    this._sidebarShownSubject.next(!hide);
  }
}
