import IUserDataService from "@/services/IUserDataService";
import { inject, injectable } from "inversify";
import TYPES from "@/types";
import User from "@/models/User";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import { INVALID_ID } from "@/models/Model";
import updateUser from "@/graphql/users/updateUser.graphql";
import createUser from "@/graphql/users/createUser.graphql";
import deleteUser from "@/graphql/users/deleteUser.graphql";
import deleteRole from "@/graphql/roles/deleteRole.graphql";
import createRole from "@/graphql/roles/createRole.graphql";
import updateRole from "@/graphql/roles/updateRole.graphql";
import getRole from "@/graphql/roles/updateRole.graphql";
import createTeacherUser from "@/graphql/users/createTeacherUser.graphql";
import resetPassword from "@/graphql/users/resetPassword.graphql";
import Role from "@/models/Role";
import forgotPassword from "@/graphql/forgotPassword.graphql";
import changePassword from "@/graphql/changePassword.graphql";
import updateForgottenPassword from "@/graphql/updateForgottenPassword.graphql";

@injectable()
export default class UserDataService implements IUserDataService {
  constructor(
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {}

  saveTeacherUser(user: User, teacherId: string): Promise<User> {
    if (user.id === INVALID_ID) {
      return this.backendApi.apolloMutation<User>(
        "createTeacherUser",
        createTeacherUser,
        {
          email: user.email,
          name: user.name,
          active: user.active,
          teacher_id: teacherId,
        }
      );
    } else {
      return this.backendApi.apolloMutation<User>("updateUser", updateUser, {
        id: user.id,
        email: user.email,
        name: user.name,
        active: user.active,
      });
    }
  }

  resetPassword(user: User): Promise<boolean> {
    return this.backendApi.apolloMutation("resetPassword", resetPassword, {
      user_id: user.id,
    });
  }

  deleteUser(user: User): Promise<boolean> {
    return this.backendApi.apolloMutation("deleteUser", deleteUser, {
      id: user.id,
    });
  }

  saveUser(user: User): Promise<User> {
    if (user.id === INVALID_ID) {
      return this.backendApi.apolloMutation<User>("createUser", createUser, {
        email: user.email,
        name: user.name,
        active: user.active,
        is_admin: user.is_admin,
        roles: user.roles ? user.roles.map((user) => user.id) : [],
        teacher: user.teacher ? user.teacher.id : null,
      });
    } else {
      return this.backendApi.apolloMutation<User>("updateUser", updateUser, {
        id: user.id,
        email: user.email,
        name: user.name,
        active: user.active,
        is_admin: user.is_admin,
        roles: user.roles ? user.roles.map((user) => user.id) : [],
        teacher: user.teacher ? user.teacher.id : null,
        student: user.student ? user.student.id : null,
      });
    }
  }

  updateUser(
    id: string,
    name: string,
    email: string,
    active: boolean,
    is_admin: boolean,
    roles: Role[],
    teacherId: string | null,
    studentId: string | null = null
  ): Promise<User> {
    return this.backendApi.apolloMutation<User>("updateUser", updateUser, {
      id,
      email,
      name,
      active,
      is_admin,
      roles: roles.map((role) => role.id),
      teacher: teacherId,
      student: studentId,
    });
  }

  deleteRole(role: Role): Promise<boolean> {
    return this.backendApi.apolloMutation("deleteRole", deleteRole, {
      id: role.id,
    });
  }

  saveRole(role: Role): Promise<Role> {
    if (role.id === INVALID_ID) {
      return this.backendApi.apolloMutation<Role>("createRole", createRole, {
        name: role.name,
        permissions: role.permissions.map((permission) => permission.id),
        users: role.users.map((user) => user.id),
      });
    } else {
      return this.backendApi.apolloMutation<Role>("updateRole", updateRole, {
        id: role.id,
        name: role.name,
        permissions: role.permissions.map((permission) => permission.id),
        users: role.users.map((user) => user.id),
      });
    }
  }

  getRoleById(id: string): Promise<Role> {
    return this.backendApi.apolloQuery<Role>("role", getRole, { id });
  }

  forgotPassword(email: string): Promise<boolean> {
    return this.backendApi.apolloMutation("forgotPassword", forgotPassword, {
      email,
    });
  }

  changePassword(
    previous_password: string,
    password: string,
    password_confirmation: string
  ): Promise<boolean> {
    return this.backendApi.apolloMutation("changePassword", changePassword, {
      previous_password,
      password,
      password_confirmation,
    });
  }

  updateForgottenPassword(
    email: string,
    token: string,
    password: string,
    password_confirmation: string
  ): Promise<boolean> {
    return this.backendApi.apolloMutation(
      "updateForgottenPassword",
      updateForgottenPassword,
      {
        email,
        token,
        password,
        password_confirmation,
      }
    );
  }

  createUser(
    name: string,
    email: string,
    active: boolean,
    is_admin: boolean,
    roles: Role[],
    teacherId: string | null,
    studentId: string | null = null
  ): Promise<User> {
    return this.backendApi.apolloMutation<User>("createUser", createUser, {
      email,
      name,
      active,
      is_admin,
      roles: roles.map((role) => role.id),
      teacher: teacherId,
      student: studentId,
    });
  }

  assignTeacher(userId: string, teacherId: string | null): Promise<User> {
    return this.backendApi.apolloMutation<User>("updateUser", updateUser, {
      id: userId,
      teacher: teacherId,
    });
  }

  assignStudent(userId: string, studentId: string | null): Promise<User> {
    return this.backendApi.apolloMutation<User>("updateUser", updateUser, {
      id: userId,
      student: studentId,
    });
  }
}
