












import Vue from "vue";
import Component from "vue-class-component";
import { HumanFriendlyError } from "@/models/HumanFriendlyError";
import { Prop } from "vue-property-decorator";
import InfoArea from "@/views/components/common/InfoArea.vue";
import { NotificationMessage } from "@/events/NotificationMessage";
import { NotificationType } from "@/events/NotificationType";
import Link from "@/models/vos/Link";

@Component({
  components: { InfoArea },
})
export default class ErrorAlert extends Vue {
  @Prop({ default: () => null }) errors!: HumanFriendlyError | null;

  private get stateMessages() {
    return this.errors && this.errors.state
      ? this.$modelFactory.modelStatusToNotificationMessages(this.errors.state)
      : [];
  }
}
