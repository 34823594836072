import { IClassService } from "@/services/IClassService";
import { Class } from "@/models/Class";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import { inject, injectable } from "inversify";
import TYPES from "@/types";
import { GraphQLResponse } from "@/models/GraphQLResponse";
import { ClassLevel } from "@/models/ClassLevel";
import { INVALID_ID } from "@/models/Model";
import updateClassLevel from "@/graphql/classLevels/updateClassLevel.graphql";
import createClassLevel from "@/graphql/classLevels/createClassLevel.graphql";
import classesOfCurrentSchoolYear from "@/graphql/classes/classesOfCurrentSchoolYear.graphql";
import classesOfSchoolYear from "@/graphql/classes/classesOfSchoolYear.graphql";
import setClassTeachers from "@/graphql/classes/setClassTeachers.graphql";
import detachClassTeacher from "@/graphql/classes/detachClassTeacher.graphql";
import getClass from "@/graphql/classes/getClass.graphql";
import getClassLevel from "@/graphql/classLevels/getClassLevel.graphql";
import deleteClassLevel from "@/graphql/classLevels/deleteClassLevel.graphql";
import { GradingModes } from "@/enums/GradingModes";
import { Location } from "vue-router";
import { RouteName } from "@/enums/RouteName";

@injectable()
export class ClassService implements IClassService {
  constructor(
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {}

  createClassLevel(
    school_year_id: string,
    level: number,
    denomination: string,
    grade_mode: GradingModes,
    skills_enabled: boolean,
    amount_difficulties: number,
    amount_stages: number,
    show_grades_in_reports: boolean,
    amount_soft_skill_stages: number,
    rate_activities_with_skills: boolean,
    show_skill_ratings_in_cspr: boolean,
    show_summed_result_in_cspr: boolean
  ): Promise<ClassLevel> {
    return this.backendApi.apolloMutation<ClassLevel>(
      "createClassLevel",
      createClassLevel,
      {
        school_year_id: school_year_id,
        level: level,
        denomination: denomination,
        amount_stages: amount_stages,
        amount_difficulties: amount_difficulties,
        skills_enabled: skills_enabled,
        grade_mode: grade_mode,
        show_grades: show_grades_in_reports,
        amount_soft_skill_stages,
        rate_activities_with_skills,
        show_skill_ratings_in_cspr,
        show_summed_result_in_cspr,
      }
    );
  }

  getClassesBySchoolYear(schoolYearId: string = ""): Promise<Class[]> {
    if (!schoolYearId) {
      return this.backendApi
        .apolloQuery<any>("currentSchoolYear", classesOfCurrentSchoolYear)
        .then((schoolYear: any) => {
          return schoolYear ? schoolYear.classes : [];
        });
    } else {
      return this.backendApi
        .apolloQuery<any>("schoolYear", classesOfSchoolYear, {
          id: schoolYearId,
        })
        .then((schoolYear: any) => {
          return schoolYear ? schoolYear.classes : [];
        });
    }
  }

  saveClassLevel(classLevel: ClassLevel): Promise<ClassLevel> {
    return this.backendApi.apolloMutation<ClassLevel>(
      "updateClassLevel",
      updateClassLevel,
      {
        id: classLevel.id,
        level: classLevel.level,
        denomination: classLevel.denomination,
        amount_stages: classLevel.amount_stages,
        amount_difficulties: classLevel.amount_difficulties,
        skills_enabled: classLevel.skills_enabled,
        grade_mode: classLevel.grade_mode,
        show_grades: classLevel.show_grades_in_reports,
        amount_soft_skill_stages: classLevel.amount_soft_skill_stages,
        show_summed_result_in_cspr: classLevel.show_summed_result_in_cspr,
        show_skill_ratings_in_cspr: classLevel.show_skill_ratings_in_cspr,
        rate_activities_with_skills: classLevel.rate_activities_with_skills,
      }
    );
  }

  deleteClassLevel(classLevel: ClassLevel): Promise<boolean> {
    return this.backendApi.apolloMutation(
      `deleteClassLevel`,
      deleteClassLevel,
      {
        id: classLevel.id,
      }
    );
  }

  deleteClass(sclass: Class): Promise<boolean> {
    return this.backendApi
      .mutation(`mutation{deleteClass(id: ${sclass.id}){id}}`)
      .then((response) => {
        return true;
      });
  }

  saveClass(sclass: Class): Promise<Class> {
    let mutation = "";
    if (sclass.id === INVALID_ID) {
      mutation = `mutation{createClass(class_level_id:${sclass.classLevel.id},denomination:"${sclass.denomination}"){id,denomination,updated_at,created_at,classLevel{id,denomination,schoolYear{id,year,created_at,updated_at},show_grades_in_reports}}}`;
      return this.backendApi
        .mutation(mutation)
        .then((response: GraphQLResponse) => {
          return response.data.createClass;
        });
    } else {
      mutation = `mutation{updateClass(id:${sclass.id},denomination:"${sclass.denomination}"){id,denomination,updated_at,created_at,classLevel{id,denomination,schoolYear{id,year,created_at,updated_at},show_grades_in_reports}}}`;
      return this.backendApi
        .mutation(mutation)
        .then((response: GraphQLResponse) => {
          return response.data.updateClass;
        });
    }
  }

  getClassLevelsBySchoolYearId(schoolYearId: string): Promise<ClassLevel[]> {
    return Promise.reject();
  }

  getClassesByClassLevelId(classLevelId: string): Promise<Class[]> {
    return this.backendApi
      .query(
        `query{classLevel(id:${classLevelId}){classes{id,denomination,updated_at,created_at,classLevel{id,level,denomination,updated_at,created_at,schoolYear{id,year,updated_at,created_at}}}}}`
      )
      .then((response: GraphQLResponse) => {
        const classes: Class[] = response.data.classLevel.classes;
        return classes;
      });
  }

  getClassLevelById(id: string): Promise<ClassLevel> {
    return this.backendApi.apolloQuery<ClassLevel>(
      "classLevel",
      getClassLevel,
      { id }
    );
  }

  getClassById(id: string): Promise<Class> {
    return this.backendApi.apolloQuery("class", getClass, { id });
  }

  setClassTeachers(
    sclass: Class,
    selectedTeachers: string[]
  ): Promise<boolean> {
    return this.backendApi.apolloMutation(
      "setClassTeachers",
      setClassTeachers,
      {
        class_id: sclass.id,
        teacher_ids: selectedTeachers,
      }
    );
  }

  detachClassTeacher(class_id: string, teacher_id: string): Promise<boolean> {
    return this.backendApi.apolloMutation(
      "detachClassTeacher",
      detachClassTeacher,
      { class_id, teacher_id }
    );
  }
}
