


























import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import ButtonVO from "@/models/vos/ButtonVO";

@Component
export default class ButtonMenu extends Vue {
  @Prop({ default: () => [] }) buttonVos!: ButtonVO[];

  protected onClick(buttonVo: ButtonVO) {
    if (buttonVo.event) {
      this.$emit("button-event", buttonVo.event);
    }
  }
}
