import { Location } from "vue-router";
import i18n from "@/i18n";

export default interface ButtonVO {
  icon?: string;
  text: string;
  event?: string;
  to?: Location;
  href?: string;
  showDivider?: boolean;
  color?: string;
  action?: () => any;
  disabled?: boolean;
  loading?: boolean;
}

export function getShowButton(to: Location) {
  return {
    color: "primary",
    icon: "mdi-open-in-new",
    to,
    text: i18n.t("actions.show").toString(),
  };
}

export function getCreateButton(
  text: string = i18n.t("actions.create").toString(),
  disabled: boolean = false
) {
  return {
    color: "success",
    icon: "mdi-plus",
    event: "create",
    text: text,
    disabled,
  };
}

export function getRefreshButton(
  loading: boolean = false,
  text: string = i18n.t("actions.refresh").toString()
): ButtonVO {
  return {
    color: "primary",
    icon: "mdi-refresh",
    event: "refresh",
    text: text,
    loading: loading,
  };
}

export const deleteButtonVO: ButtonVO = {
  text: i18n.t("actions.delete").toString(),
  icon: "mdi-delete-outline",
  color: "error",
  event: "delete",
};

export const createButtonVO: ButtonVO = getCreateButton();

export const refreshButtonVO: ButtonVO = getRefreshButton();

export const editButtonVO: ButtonVO = {
  color: "primary",
  icon: "mdi-pencil-outline",
  event: "edit",
  text: i18n.t("actions.edit").toString(),
};

export const editAllButtonVO: ButtonVO = {
  color: "primary",
  icon: "mdi-pencil-plus-outline",
  event: "edit-all",
  text: i18n.t("actions.editAll").toString(),
};

export function getDownloadButton(
  href: string,
  disabled: boolean = false,
  text: string = i18n.t("actions.download").toString()
): ButtonVO {
  return {
    color: "primary",
    icon: "mdi-file-download-outline",
    event: "download",
    disabled,
    text,
    href,
  };
}
