























import { Component, Prop, Vue } from "vue-property-decorator";
import i18n from "@/i18n";
import TYPES from "@/types";
import { INotificationService } from "@/services/INotificationService";
import { lazyInject } from "@/inversify.config";
import { AbstractApiError } from "@/errors/AbstractApiError";
import { HumanFriendlyError } from "@/models/HumanFriendlyError";
import ErrorAlert from "@/views/components/common/ErrorAlert.vue";
import PageButton from "@/views/components/common/buttons/page/PageButton.vue";
import IDialog from "@/views/components/common/dialog/IDialog.vue";
import IConfirmDialog from "@/views/components/common/dialog/IConfirmDialog.vue";

@Component({
  components: { IConfirmDialog, IDialog, PageButton, ErrorAlert },
})
export default class PageConfirmButton extends Vue {
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: i18n.t("delete.question") }) readonly question!: string;
  @Prop({ default: "red" }) color!: string;
  @Prop({ default: "mdi-delete-outline" }) icon!: string;
  @Prop({ default: i18n.t("delete.title") }) heading!: string;
  @Prop({ default: i18n.t("actions.cancel") }) cancelButtonText!: string;
  @Prop({ default: i18n.t("actions.confirm") }) confirmButtonText!: string;
  @Prop({ default: "red" }) confirmButtonColor!: string;
  @Prop({ default: i18n.t("actions.delete") }) tooltip!: string;
  @Prop({ default: () => Promise.resolve(true) }) action!: () => Promise<any>;
  protected dialog: boolean = false;
  private errors: HumanFriendlyError = new HumanFriendlyError();
  private loading: boolean = false;

  close() {
    this.errors.clear();
    this.dialog = false;
  }

  confirmed() {
    this.loading = true;
    this.errors.clear();
    this.action()
      .then(() => {
        this.close();
        this.$emit("confirmed");
      })
      .catch((error) => {
        this.$notificationService.reportError(error);
        if (error instanceof AbstractApiError) {
          this.errors.apiError = error;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
