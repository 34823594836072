
















import { Component, Vue } from "vue-property-decorator";
import IAppBar from "@/views/components/common/toolbar/IAppBar.vue";
import ButtonVO, {
  getRefreshButton,
  refreshButtonVO,
} from "@/models/vos/ButtonVO";
import { getModule } from "vuex-module-decorators";
import PermissionsModule from "@/modules/PermissionsModule";
import PropertyArea from "@/views/components/common/PropertyArea.vue";
import { AttributeVO } from "@/models/vos/AttributeVO";
import { Class } from "@/models/Class";
import InfoArea from "@/views/components/common/InfoArea.vue";
import { ClassSubject } from "@/models/ClassSubject";
import Student from "@/models/Student";
import classSubjectsOfStudent from "@/graphql/classSubjects/classSubjectsOfStudent.graphql";
import ClassSubjectIterator from "@/views/components/subject/ClassSubjectIterator.vue";

@Component({
  components: {
    ClassSubjectIterator,
    InfoArea,
    PropertyArea,
    IAppBar,
  },
  apollo: {
    myClassSubjects: {
      query() {
        return classSubjectsOfStudent;
      },
      variables() {
        return {
          student_id: this.studentId,
          school_year_id: this.currentSchoolYearId,
          term: this.currentTerm,
        };
      },
      update: (data) => data.student?.classSubjects ?? [],
    },
  },
})
export default class Home extends Vue {
  protected myClasses: Class[] = [];
  protected myClassSubjects: ClassSubject[] = [];
  protected myStudents: Student[] = [];
  protected permissionModule: PermissionsModule = getModule(PermissionsModule);

  protected get titleButtons(): ButtonVO[] {
    return [];
  }

  protected get studentId() {
    return getModule(PermissionsModule).studentId;
  }

  protected get currentSchoolYearId() {
    return getModule(PermissionsModule).currentSchoolYear?.id;
  }

  protected get currentTerm() {
    return getModule(PermissionsModule).currentTerm;
  }

  protected get welcome() {
    return this.$t("welcome", {
      user: getModule(PermissionsModule).userName,
    });
  }

  protected get title() {
    return this.$t("localtions.home");
  }

  protected get info(): AttributeVO[] {
    return [
      {
        title: this.$t("locations.currentYear").toString(),
        value:
          getModule(PermissionsModule).currentSchoolYear?.denomination ?? "",
      },
    ];
  }

  protected get messages() {
    return [];
  }

  protected get studentButtons() {
    return [getRefreshButton(this.$apollo.queries.myStudents.loading)];
  }

  protected get classSubjectButtons() {
    return [refreshButtonVO];
  }

  protected get classButtons() {
    return [getRefreshButton(this.$apollo.queries.myClasses.loading)];
  }
}
