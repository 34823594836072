


















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class TableRefreshButton extends Vue {
  @Prop({ default: false }) loading!: boolean;
}
