import IActivityDataService from "@/services/IActivityDataService";
import { inject, injectable } from "inversify";
import TYPES from "@/types";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import ActivityType from "@/models/ActivityType";
import createActivityType from "@/graphql/activityTypes/createActivityType.graphql";
import updateActivityType from "@/graphql/activityTypes/updateActivityType.graphql";
import deleteActivityType from "@/graphql/activityTypes/deleteActivityType.graphql";
import deleteActivity from "@/graphql/activities/deleteActivity.graphql";
import createActivity from "@/graphql/activities/createActivity.graphql";
import updateActivity from "@/graphql/activities/updateActivity.graphql";
import createStudentActivities from "@/graphql/activities/createStudentActivities.graphql";
import getOrCreateStudentActivitiesWithSkills from "@/graphql/activities/getOrCreateStudentActivitiesWithSkills.graphql";
import updateStudentActivity from "@/graphql/activities/updateStudentActivity.graphql";
import updateStudentSoftSkills from "@/graphql/classSubjectPartReport/updateStudentSoftSkills.graphql";
import updateStudentSkills from "@/graphql/classSubjectPartReport/updateStudentSkills.graphql";
import Activity from "@/models/Activity";
import ClassSubjectPart from "@/models/ClassSubjectPart";
import StudentActivity from "@/models/StudentActivity";
import { ActivityTypeRatingMode } from "@/enums/ActivityTypeRatingMode";
import { ActivityTypeCategory } from "@/enums/ActivityTypeCategory";
import StudentActivitySkill from "@/models/StudentActivitySkill";
import StudentSoftSkill from "@/models/StudentSoftSkill";
import StudentSkill from "@/models/StudentSkill";

@injectable()
export default class ActivityDataService implements IActivityDataService {
  constructor(
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {}

  saveActivityType(type: ActivityType): Promise<ActivityType> {
    return this.backendApi.apolloMutation(
      "updateActivityType",
      updateActivityType,
      {
        id: type.id,
        description: type.description,
        denomination: type.denomination,
        category: type.category,
        rating_mode: type.rating_mode,
      }
    );
  }

  createActivityType(
    denomination: string,
    description: string,
    category: ActivityTypeCategory,
    rating_mode: ActivityTypeRatingMode
  ): Promise<ActivityType> {
    return this.backendApi.apolloMutation(
      "createActivityType",
      createActivityType,
      {
        denomination,
        description,
        category,
        rating_mode,
      }
    );
  }

  deleteActivityType(type: ActivityType): Promise<boolean> {
    return this.backendApi.apolloMutation(
      "deleteActivityType",
      deleteActivityType,
      { id: type.id }
    );
  }

  deleteActivity(activity: Activity): Promise<boolean> {
    return this.backendApi.apolloMutation("deleteActivity", deleteActivity, {
      id: activity.id,
    });
  }

  saveActivity(
    id: string,
    activity_type_id: string,
    denomination: string,
    description: string,
    due_at: string
  ): Promise<Activity> {
    return this.backendApi.apolloMutation("updateActivity", updateActivity, {
      id,
      activity_type_id,
      description,
      denomination,
      due_at,
    });
  }

  createStudentActivity(): Promise<ClassSubjectPart> {
    return Promise.reject(new Error("TODO: Not implemented yet!"));
  }

  createStudentActivities(
    activity_id: string,
    student_ids: string[],
    stage: number | null,
    difficulty_id: string | null,
    grade: number | null,
    without_rating: boolean,
    remark: string
  ): Promise<Activity> {
    return this.backendApi.apolloMutation<Activity>(
      "createStudentActivities",
      createStudentActivities,
      {
        activity_id,
        student_ids,
        difficulty_id,
        stage,
        grade,
        without_rating,
        remark,
      }
    );
  }

  difficultyToChar(difficulty: number): string {
    return String.fromCharCode(0x40 + difficulty);
  }

  saveStudentActivity(
    id: string,
    stage: number | null,
    difficulty_id: string | null,
    grade: number | null,
    excluded_from_rating: boolean,
    remark: string,
    studentActivitySkills: StudentActivitySkill[]
  ): Promise<StudentActivity> {
    return this.backendApi.apolloMutation<StudentActivity>(
      "updateStudentActivity",
      updateStudentActivity,
      {
        id,
        difficulty_id,
        stage,
        grade,
        excluded_from_rating,
        remark,
        student_activity_skills: studentActivitySkills.map((skill) => ({
          id: skill.id,
          skill_id: skill.skill?.id ?? null,
          stage: skill.stage,
          excluded_from_rating: skill.excluded_from_rating,
          performance_refused: skill.performance_refused,
        })),
      }
    );
  }

  createActivity(
    class_subject_part_id: string,
    activity_type_id: string,
    denomination: string,
    description: string,
    due_at: string
  ): Promise<Activity> {
    return this.backendApi.apolloMutation("createActivity", createActivity, {
      class_subject_part_id,
      activity_type_id,
      description,
      denomination,
      due_at,
    });
  }

  getOrCreateStudentActivities(activityId: string): Promise<{
    ratedStudentActivities: StudentActivity[];
    unratedStudentActivities: StudentActivity[];
  }> {
    return this.backendApi.apolloMutation<any>(
      "createStudentActivities",
      getOrCreateStudentActivitiesWithSkills,
      {
        activity_id: activityId,
      }
    );
  }

  saveStudentSoftSkills(
    studentSoftSkills: StudentSoftSkill[]
  ): Promise<StudentSoftSkill[]> {
    return this.backendApi.apolloMutation<StudentSoftSkill[]>(
      "updateStudentSoftSkills",
      updateStudentSoftSkills,
      {
        student_soft_skills: studentSoftSkills.map((softSkill) => ({
          id: softSkill.id,
          stage: softSkill.stage,
          grade: softSkill.grade,
          excluded_from_rating: softSkill.excluded_from_rating,
        })),
      }
    );
  }

  saveStudentSkills(studentSkills: StudentSkill[]): Promise<StudentSkill[]> {
    return this.backendApi.apolloMutation<StudentSkill[]>(
      "updateStudentSkills",
      updateStudentSkills,
      {
        student_skills: studentSkills.map((studentSkill) => ({
          id: studentSkill.id,
          stage: studentSkill.stage,
          skill_id: studentSkill.skill ? studentSkill.skill.id : null,
          excluded_from_rating: studentSkill.excluded_from_rating,
          performance_refused: studentSkill.performance_refused,
        })),
      }
    );
  }
}
