





























import Component, { mixins } from "vue-class-component";
import VisibleVModel from "@/views/mixins/VisibleVModel.vue";
import { getModule } from "vuex-module-decorators";
import PermissionsModule from "@/modules/PermissionsModule";
import { Location } from "vue-router";
import { RouteName } from "@/enums/RouteName";

@Component({
  components: {},
})
export default class Sidebar extends mixins(VisibleVModel) {
  protected showCreateActivity: boolean = false;
  protected showCreateStudentActivity: boolean = false;

  protected get isTeacher(): boolean {
    return (
      getModule(PermissionsModule).isTeacher ||
      this.$hasAnyPermission(this.$permissions.EDIT_ALL_TEACHER)
    );
  }

  protected get teacherId() {
    return getModule(PermissionsModule).activeUserInfo?.teacher_id ?? null;
  }
}
