




















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class BasicButton extends Vue {
  @Prop({ required: true }) tooltip!: string;
  @Prop({ required: true }) icon!: string;
  @Prop({ default: false }) small!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: "primary" }) color!: string;
  @Prop({
    default: () => "",
  })
  to!: string | Location;
  @Prop({ default: false }) fab!: boolean;
}
