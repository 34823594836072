import IImportDataService from "@/services/IImportDataService";
import { inject, injectable } from "inversify";
import Import from "@/models/Import";
import TYPES from "@/types";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import createImport from "@/graphql/import/createImport.graphql";
import deleteImport from "@/graphql/import/deleteImport.graphql";
import updateImportSubject from "@/graphql/import/updateImportSubject.graphql";
import updateImportTeacher from "@/graphql/import/updateImportTeacher.graphql";
import processImport from "@/graphql/import/processImport.graphql";
import ImportSubject from "@/models/ImportSubject";
import ImportTeacher from "@/models/ImportTeacher";
import { ImportState } from "@/enums/ImportState";

@injectable()
export default class ImportDataService implements IImportDataService {
  constructor(
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {}

  createImport(schoolYearId: string, csv: File): Promise<Import> {
    return this.backendApi.apolloMutation("createImport", createImport, {
      school_year_id: schoolYearId,
      file: csv,
    });
  }

  deleteImport(id: string): Promise<boolean> {
    return this.backendApi
      .apolloMutation("deleteImport", deleteImport, { id: id })
      .then(() => true);
  }

  saveImportSubject(importSubject: ImportSubject): Promise<ImportSubject> {
    return this.backendApi.apolloMutation(
      "updateImportSubject",
      updateImportSubject,
      {
        id: importSubject.id,
        school_subject_id: importSubject.schoolSubject
          ? importSubject.schoolSubject.id
          : null,
        subject_id: importSubject.subject ? importSubject.subject.id : null,
      }
    );
  }

  saveImportTeacher(importTeacher: ImportTeacher): Promise<ImportTeacher> {
    return this.backendApi.apolloMutation(
      "updateImportTeacher",
      updateImportTeacher,
      {
        id: importTeacher.id,
        teacher_id: importTeacher.teacher ? importTeacher.teacher.id : null,
      }
    );
  }

  processImport(
    importModel: Import,
    deactivateNotIncludedStudents: boolean
  ): Promise<Import> {
    return this.backendApi.apolloMutation("processImport", processImport, {
      id: importModel.id,
      state: ImportState.PROCESSED,
      deactivateNotIncludedStudents,
    });
  }
}
