



































import { Component, Prop, PropSync } from "vue-property-decorator";
import Vue from "vue";
import ButtonVO from "@/models/vos/ButtonVO";
import ButtonMenu from "@/views/components/common/buttons/ButtonMenu.vue";
import PageButton from "@/views/components/common/buttons/page/PageButton.vue";
import SearchField from "@/views/components/common/SearchField.vue";

@Component({
  components: { SearchField, PageButton, ButtonMenu },
})
export default class IAppBar extends Vue {
  @Prop({ default: () => [] }) buttonVos!: ButtonVO[];
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) tooltip!: string;
  @Prop({ default: true }) elevateOnScroll!: boolean;
  @PropSync("search") syncedSearch!: string;

  protected onButtonClicked(eventName: string) {
    this.$emit(`button-event:${eventName}`);
  }

  protected get visibleButtons(): ButtonVO[] {
    const index: number = this.$vuetify.breakpoint.mdAndUp ? 3 : 0;

    return index ? this.buttonVos.slice(0, index) : [];
  }

  protected get showMenu(): boolean {
    return this.visibleButtons.length < this.buttonVos.length;
  }
}
