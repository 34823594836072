
import Vue from "vue";
import Component from "vue-class-component";
import { Model, Watch } from "vue-property-decorator";

@Component({})
export default class VisibleVModel extends Vue {
  @Model("changed") visible!: boolean;

  protected get isVisible() {
    return this.visible;
  }

  protected set isVisible(visible: boolean) {
    this.$emit("changed", visible);
  }

  @Watch("visible")
  protected onVisibleChanged(isVisible: boolean) {
    if (!isVisible) {
      this.reset();
    }
  }

  protected reset() {
    //
  }
}
