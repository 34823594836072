import _Vue from "vue";
import { RouteName } from "@/enums/RouteName";
import moment from "moment";
import { INotificationService } from "@/services/INotificationService";
import { container } from "@/inversify.config";
import TYPES from "@/types";
import IModelFactory from "@/services/IModelFactory";
import DateFormatter from "@/helpers/DateFormatter";
import { getModule } from "vuex-module-decorators";
import PermissionsModule from "@/modules/PermissionsModule";
import { PermissionName } from "@/models/Permission";

export function RoutePlugin(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$permissions = PermissionName;
  Vue.prototype.$isAdmin = () => getModule(PermissionsModule).isAdmin;
  Vue.prototype.$hasPermission = (permission: PermissionName) =>
    getModule(PermissionsModule).hasPermission(permission);

  Vue.prototype.$hasAnyPermission = (...permissionNames: PermissionName[]) =>
    getModule(PermissionsModule).hasAnyPermission(...permissionNames);
  Vue.prototype.$routes = RouteName;
  Vue.prototype.$moment = function () {
    return moment;
  };
  Vue.prototype.$notificationService = container.get<INotificationService>(
    TYPES.INotificationService
  );
  Vue.prototype.$modelFactory = container.get<IModelFactory>(
    TYPES.IModelFactory
  );
  Vue.prototype.$formatDate = DateFormatter.format;
}

declare module "vue/types/vue" {
  interface Vue {
    $routes: typeof RouteName;
    $moment: typeof moment;
    $notificationService: INotificationService;
    $modelFactory: IModelFactory;
    $formatDate: typeof DateFormatter.format;
    $hasPermission: (permissionName: PermissionName) => boolean;
    $hasAnyPermission: (...permissionNames: PermissionName[]) => boolean;
    $isAdmin: () => boolean;
    $permissions: typeof PermissionName;
  }
}
