






















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class TableItemButton extends Vue {
  @Prop({ required: true }) tooltip!: string;
  @Prop({ required: true }) icon!: string;
  @Prop({ default: "primary" }) color!: string;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: "" }) href!: string;
  @Prop({ default: "" }) target!: string;
  @Prop({
    default: () => "",
  })
  to!: string | Location;

  protected onClick(e: Event) {
    this.$emit("click", e);
  }
}
