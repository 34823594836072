import "reflect-metadata";
import { Container } from "inversify";

import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import { GraphQLApi } from "@/services/impl/GraphQLApi";
import axios, { AxiosInstance } from "axios";
import getDecorators from "inversify-inject-decorators";
import { INotificationService } from "@/services/INotificationService";
import { NotificationService } from "@/services/impl/NotificationService";
import { IUiStateService } from "@/services/IUiStateService";
import { UiStateService } from "@/services/impl/UiStateService";
import { ISchoolYearService } from "@/services/ISchoolYearService";
import { SchoolYearService } from "@/services/impl/SchoolYearService";
import { IClassService } from "@/services/IClassService";
import { ClassService } from "@/services/impl/ClassService";
import ISubjectService from "@/services/ISubjectService";
import SubjectService from "@/services/impl/SubjectService";
import IModelFactory from "@/services/IModelFactory";
import ModelFactory from "@/services/impl/ModelFactory";
import StudentDataService from "@/services/impl/StudentDataService";
import IStudentDataService from "@/services/IStudentDataService";
import ITeacherDataService from "@/services/ITeacherDataService";
import TeacherDataService from "@/services/impl/TeacherDataService";
import UserDataService from "@/services/impl/UserDataService";
import IUserDataService from "@/services/IUserDataService";
import ISkillDataService from "@/services/ISkillDataService";
import SkillDataService from "@/services/impl/SkillDataService";
import IActivityDataService from "@/services/IActivityDataService";
import ActivityDataService from "@/services/impl/ActivityDataService";
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";
import { ApolloClient } from "apollo-client";
import { SubscriptionClient } from "subscriptions-transport-ws";
import IClassSubjectPartReportDataService from "@/services/IClassSubjectPartReportDataService";
import ClassSubjectPartReportDataService from "@/services/impl/ClassSubjectPartReportDataService";
import IStudentReportDataService from "@/services/IStudentReportDataService";
import StudentReportDataService from "@/services/impl/StudentReportDataService";
import { createUploadLink } from "apollo-upload-client";
import IImportDataService from "@/services/IImportDataService";
import ImportDataService from "@/services/impl/ImportDataService";
import IAuthenticationService from "@/services/IAuthenticationService";
import AuthenticationService from "@/services/impl/AuthenticationService";
import { setContext } from "apollo-link-context";
import ISchoolSubjectGroupDataService from "@/services/ISchoolSubjectGroupDataService";
import SchoolSubjectGroupDataService from "@/services/impl/SchoolSubjectGroupDataService";
import TYPES from "@/types";

export const container = new Container();
export const { lazyInject } = getDecorators(container, false);

// Name of the localStorage item
const AUTH_TOKEN = "access_token";

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || "/graphql";

// HTTP connection to the API
// const httpLink = createHttpLink({
//     // You should use an absolute URL here
//     fetch:fetch as any,
//     uri: process.env.VUE_APP_GRAPHQL_HTTP || "/graphql",
// })
// Config
const xsrfTokenLink = setContext((_, { headers }) => {
  // get the authentication token from ApplicationSettings if it exists
  const token = localStorage.getItem("XSRF-TOKEN");

  // return the headers to the context so HTTP link can read them
  return {
    headers: {
      ...headers,
      "X-XSRF-TOKEN": token ? token : null,
    },
  };
});

const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || "ws://localhost:4000/graphql",
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  defaultHttpLink: false,

  link: xsrfTokenLink.concat(
    //@ts-ignore
    createUploadLink({
      uri: process.env.VUE_APP_GRAPHQL_HTTP || "/graphql",
      credentials: "include",
    })
  ),
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  httpLinkOptions: { fetch: fetch },
  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Create apollo client
//@ts-ignore
const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions,
});
container
  .bind<ISchoolSubjectGroupDataService>(TYPES.ISchoolSubjectDataGroupService)
  .to(SchoolSubjectGroupDataService)
  .inSingletonScope();
container
  .bind<IAuthenticationService>(TYPES.IAuthenticationService)
  .to(AuthenticationService)
  .inSingletonScope();
container
  .bind<IImportDataService>(TYPES.IImportDataService)
  .to(ImportDataService)
  .inSingletonScope();
container
  .bind<IStudentReportDataService>(TYPES.IStudentReportDataService)
  .to(StudentReportDataService)
  .inSingletonScope();
container
  .bind<IClassSubjectPartReportDataService>(
    TYPES.IClassSubjectPartReportDataService
  )
  .to(ClassSubjectPartReportDataService)
  .inSingletonScope();
container
  .bind<IStudentDataService>(TYPES.IStudentDataService)
  .to(StudentDataService)
  .inSingletonScope();
container
  .bind<IActivityDataService>(TYPES.IActivityDataService)
  .to(ActivityDataService)
  .inSingletonScope();
container
  .bind<ISkillDataService>(TYPES.ISkillDataService)
  .to(SkillDataService)
  .inSingletonScope();
container
  .bind<IUserDataService>(TYPES.IUserDataService)
  .to(UserDataService)
  .inSingletonScope();
container
  .bind<ITeacherDataService>(TYPES.ITeacherDataService)
  .to(TeacherDataService)
  .inSingletonScope();
container
  .bind<IModelFactory>(TYPES.IModelFactory)
  .to(ModelFactory)
  .inSingletonScope();
container
  .bind<IGraphQLBackendApi>(TYPES.IGraphQLBackendApi)
  .to(GraphQLApi)
  .inSingletonScope();
container
  .bind<IUiStateService>(TYPES.IUIStateService)
  .to(UiStateService)
  .inSingletonScope();
container
  .bind<IClassService>(TYPES.IClassService)
  .to(ClassService)
  .inSingletonScope();
container
  .bind<ISchoolYearService>(TYPES.ISchoolYearService)
  .to(SchoolYearService)
  .inSingletonScope();
container
  .bind<ISubjectService>(TYPES.ISubjectService)
  .to(SubjectService)
  .inSingletonScope();
container
  .bind<AxiosInstance>(TYPES.AxiosInstance)
  .toDynamicValue((context) => {
    const axiosInstance = axios.create({
      // baseURL: process.env.VUE_APP_GRAPHQL_HTTP
    });
    return axiosInstance;
  })
  .inSingletonScope();

container
  .bind<INotificationService>(TYPES.INotificationService)
  .to(NotificationService)
  .inSingletonScope();

container
  .bind<ApolloClient<any>>(TYPES.ApolloClient)
  .toDynamicValue((context) => {
    return apolloClient;
  });
container
  .bind<SubscriptionClient>(TYPES.SubscriptionClient)
  .toDynamicValue((context) => {
    return wsClient;
  });
