import IStudentDataService from "@/services/IStudentDataService";
import Student from "@/models/Student";
import { inject, injectable } from "inversify";
import IModelFactory from "@/services/IModelFactory";
import TYPES from "@/types";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import createStudent from "@/graphql/students/createStudent.graphql";
import updateStudent from "@/graphql/students/updateStudent.graphql";
import deleteStudent from "@/graphql/students/deleteStudent.graphql";
import uploadStudentImage from "@/graphql/students/uploadStudentImage.graphql";
import allStudents from "@/graphql/students/allStudents.graphql";
import updateSchoolSubjectAssignment from "@/graphql/studentSubject/updateSchoolSubjectAssignment.graphql";
import updateSchoolSubjectAssignments from "@/graphql/studentSubject/updateSchoolSubjectAssignments.graphql";
import deleteSchoolSubjectAssignment from "@/graphql/studentSubject/deleteSchoolSubjectAssignment.graphql";
import updateStudentSubject from "@/graphql/studentSubject/updateStudentSubject.graphql";
import uploadStudentImages from "@/graphql/students/uploadStudentImages.graphql";
import getStudent from "@/graphql/students/getStudent.graphql";
import { INVALID_ID } from "@/models/Model";
import StudentSubject from "@/models/StudentSubject";
import SchoolSubjectAssignment from "@/models/SchoolSubjectAssignment";
import SchoolSubjectAssignmentEditItem from "@/models/vos/SchoolSubjectAssignmentEditItem";

@injectable()
export default class StudentDataService implements IStudentDataService {
  constructor(
    @inject(TYPES.IModelFactory) private modelFactory: IModelFactory,
    @inject(TYPES.IGraphQLBackendApi) private backendApi: IGraphQLBackendApi
  ) {}

  deleteStudent(student: Student): Promise<boolean> {
    return this.backendApi
      .apolloMutation("deleteStudent", deleteStudent, { id: student.id })
      .then(() => {
        return true;
      });
  }

  getStudents(): Promise<Student[]> {
    return this.backendApi.apolloQuery<Student[]>("allStudents", allStudents);
  }

  saveStudent(student: Student): Promise<Student> {
    if (student.id === INVALID_ID) {
      return this.backendApi.apolloMutation<Student>(
        "createStudent",
        createStudent,
        {
          first_name: student.first_name,
          last_name: student.last_name,
          birthday: student.birthday,
          birthplace: student.birthplace,
          entry_date: student.entry_date,
          exit_date: student.exit_date,
          current_class_id: student.currentClass ? student.currentClass.id : "",
          image: student.image ? student.image : "",
        }
      );
    } else {
      return this.backendApi.apolloMutation<Student>(
        "updateStudent",
        updateStudent,
        {
          id: student.id,
          first_name: student.first_name,
          last_name: student.last_name,
          birthday: student.birthday,
          birthplace: student.birthplace,
          entry_date: student.entry_date,
          exit_date: student.exit_date,
          current_class_id: student.currentClass ? student.currentClass.id : "",
          image: student.image ? student.image : "",
        }
      );
    }
  }

  getStudentById(id: string): Promise<Student> {
    return this.backendApi.apolloQuery<Student>("student", getStudent, {
      id,
    });
  }

  getStudentSubjectName(studentSubject: StudentSubject): string {
    return `${studentSubject.schoolSubject.denomination} - ${studentSubject.student.full_name}`;
  }

  updateStudentSubject(
    id: string,
    subject_id: string | null,
    class_subject_id: string | null
  ): Promise<StudentSubject> {
    return this.backendApi.apolloMutation<StudentSubject>(
      "updateStudentSubject",
      updateStudentSubject,
      {
        id,
        subject_id,
        class_subject_id,
      }
    );
  }

  getInitials(student: Student): string {
    return `${student.first_name.substr(0, 1)}${student.last_name.substr(
      0,
      1
    )}`;
  }

  updateSchoolSubjectAssignment(
    school_year_id: string,
    student_id: string,
    school_subject_id: string,
    class_subject_first_term_id: string | null,
    class_subject_second_term_id: string | null
  ): Promise<SchoolSubjectAssignment> {
    return this.backendApi.apolloMutation<SchoolSubjectAssignment>(
      "updateSchoolSubjectAssignment",
      updateSchoolSubjectAssignment,
      {
        school_year_id,
        student_id,
        school_subject_id,
        class_subject_first_term_id,
        class_subject_second_term_id,
      }
    );
  }

  deleteSchoolSubjectAssignment(
    school_year_id: string,
    student_id: string,
    school_subject_id: string
  ): Promise<boolean> {
    return this.backendApi.apolloMutation<boolean>(
      "deleteSchoolSubjectAssignment",
      deleteSchoolSubjectAssignment,
      {
        school_year_id,
        student_id,
        school_subject_id,
      }
    );
  }

  updateSchoolSubjectAssignments(
    assignments: SchoolSubjectAssignmentEditItem[]
  ): Promise<SchoolSubjectAssignment[]> {
    return this.backendApi.apolloMutation<SchoolSubjectAssignment[]>(
      "updateSchoolSubjectAssignments",
      updateSchoolSubjectAssignments,
      {
        assignments: assignments.map((assignment) => {
          return {
            student_id: assignment.student.id,
            school_year_id: assignment.schoolYear.id,
            school_subject_id: assignment.schoolSubject.id,
            class_subject_first_term_id:
              assignment.classSubjectFirstTerm?.id ?? null,
            class_subject_second_term_id:
              assignment.classSubjectSecondTerm?.id ?? null,
          };
        }),
      }
    );
  }

  uploadImage(studentId: string, image: File | null): Promise<Student> {
    return this.backendApi.apolloMutation<Student>(
      "uploadStudentImage",
      uploadStudentImage,
      {
        student_id: studentId,
        image,
      }
    );
  }

  uploadImages(zipFile: File): Promise<boolean> {
    return this.backendApi.apolloMutation<boolean>(
      "uploadStudentImages",
      uploadStudentImages,
      {
        images: zipFile,
      }
    );
  }
}
