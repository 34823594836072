
























































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ButtonMenu from "@/views/components/common/buttons/ButtonMenu.vue";
import ButtonVO from "@/models/vos/ButtonVO";
import { AttributeVO } from "@/models/vos/AttributeVO";
import BooleanIcon from "@/views/components/common/BooleanIcon.vue";
import ItemCardVo from "@/models/vos/ItemCardVo";

@Component({
  components: { BooleanIcon, ButtonMenu },
})
export default class ItemCard<T> extends Vue {
  @Prop({ default: () => [] }) item!: ItemCardVo;
  @Prop({ default: false }) noItemTitle!: boolean;

  protected get showImage() {
    return this.image && this.$vuetify.breakpoint.mdAndUp;
  }

  protected get showImageText() {
    return this.imageText && !this.image && this.$vuetify.breakpoint.mdAndUp;
  }

  protected get buttons() {
    return this.item.buttons ?? [];
  }

  protected get attributes() {
    return this.item.attributes;
  }

  protected get image() {
    return this.item.image;
  }

  protected get imageText() {
    return this.item.imageText;
  }

  protected get colSize() {
    return this.showImage || this.showImageText ? 7 : 12;
  }

  protected isProgress(attribute: AttributeVO): boolean {
    return !!attribute.target;
  }

  protected getProgress(attribute: AttributeVO): number {
    return attribute.current && attribute.target && attribute.target > 0
      ? Math.round((attribute.current * 100) / attribute.target)
      : 0;
  }

  protected getProgressColor(attribute: AttributeVO): string {
    let color = "red";
    let progress = this.getProgress(attribute);
    if (progress >= 100) {
      color = "success";
    } else if (progress >= 75) {
      color = "teal";
    } else if (progress >= 50) {
      color = "warning";
    } else if (progress >= 25) {
      color = "orange";
    }

    return color;
  }
}
