













































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { NotificationMessage } from "@/events/NotificationMessage";
import { NotificationType } from "@/events/NotificationType";
import ExpandWithToolbarFlat from "@/views/components/common/ExpandWithToolbarFlat.vue";
@Component({
  components: { ExpandWithToolbarFlat },
})
export default class InfoArea extends Vue {
  @Prop({ required: true }) messages!: NotificationMessage[];

  private get errors() {
    return this.messages.filter(
      (message) => message.event === NotificationType.Error
    );
  }

  private get warnings() {
    return this.messages.filter(
      (message) => message.event === NotificationType.Warning
    );
  }

  private get infos() {
    return this.messages.filter(
      (message) => message.event === NotificationType.Info
    );
  }

  private get successes() {
    return this.messages.filter(
      (message) => message.event === NotificationType.Success
    );
  }
}
