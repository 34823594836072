

















































import Vue from "vue";
import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import PermissionsModule from "@/modules/PermissionsModule";
import { RouteName } from "@/enums/RouteName";
import { IApiError } from "@/errors/IApiError";
import { ErrorType } from "@/enums/ErrorType";
import PageConfirmButton from "@/views/components/common/PageConfirmButton.vue";
import { HumanFriendlyError } from "@/models/HumanFriendlyError";
import TYPES from "@/types";
import IAuthenticationService from "@/services/IAuthenticationService";
import { lazyInject } from "@/inversify.config";
import ErrorAlert from "@/views/components/common/ErrorAlert.vue";
import IUserDataService from "@/services/IUserDataService";

@Component({
  components: { ErrorAlert, PageConfirmButton },
  metaInfo() {
    return {
      title: this.$t("metaInfo.forgotPassword.title").toString(),
    };
  },
})
export default class ForgotPassword extends Vue {
  @lazyInject(TYPES.IUserDataService)
  userService!: IUserDataService;
  protected loading: boolean = false;
  protected email: string = "";
  protected errors: HumanFriendlyError = new HumanFriendlyError();

  protected resetPassword(event: Event) {
    this.loading = true;
    return this.userService
      .forgotPassword(this.email)
      .then((success) => {
        this.$notificationService.reportSuccess(
          this.$t("resetPasswordSuccess")
        );
        this.$router.push({ name: RouteName.LOGIN });
        return true;
      })
      .catch((reason: IApiError) => {
        this.errors.apiError = reason;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
