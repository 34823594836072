import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Permission, PermissionName } from "@/models/Permission";
import store from "@/store";
import TYPES from "@/types";
import { lazyInject } from "@/inversify.config";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import { UserInfo } from "@/models/UserInfo";
import currentSchoolYear from "@/graphql/schoolYears/currentSchoolYear.graphql";
import { SchoolYear } from "@/models/SchoolYear";

@Module({ name: "permissions", dynamic: true, store })
export default class PermissionsModule extends VuexModule {
  @lazyInject(TYPES.IGraphQLBackendApi) backendApi!: IGraphQLBackendApi;

  activeUserInfo: UserInfo | null = null;
  accessToken: string = "";
  loading: boolean = false;
  appVersion: string = process.env.VUE_APP_VERSION || "0";
  currentSchoolYear: SchoolYear | null = null;
  appName: string = "IGS Schüler App";
  impersonationActive: boolean = false;

  maintenanceModeEnabled: boolean = false;
  passwordConfirmationRequired: boolean = false;
  twoFactorChallengeActivated: boolean = false;

  get hasPermission() {
    return (permissionName: PermissionName) => {
      const hasPermission: boolean = false;
      if (this.activeUserInfo) {
        return (
          this.activeUserInfo.is_admin ||
          this.activeUserInfo.permissions.findIndex(
            (permission) => permission.name === permissionName
          ) !== -1
        );
      }
      return hasPermission;
    };
  }

  get hasAnyPermission() {
    return (...permissionNames: PermissionName[]) => {
      let hasPermission = false;
      for (const permissionName of permissionNames) {
        hasPermission = this.hasPermission(permissionName);
        if (hasPermission) {
          break;
        }
      }
      return hasPermission;
    };
  }

  get currentTerm(): number {
    return this.currentSchoolYear?.first_term_completed_at ? 2 : 1 ?? 1;
  }

  get isLoggedIn(): boolean {
    return this.activeUserInfo !== null;
  }

  get isTeacher(): boolean {
    return this.activeUserInfo?.is_teacher ?? false;
  }

  get isStudent(): boolean {
    return this.activeUserInfo?.is_student ?? false;
  }

  get studentId() {
    return this.activeUserInfo?.student_id;
  }

  get userName(): string {
    return this.activeUserInfo ? this.activeUserInfo.name : "";
  }
  get email(): string {
    return this.activeUserInfo ? this.activeUserInfo.email : "";
  }

  get isAdmin(): boolean {
    return this.activeUserInfo?.is_admin ?? false;
  }

  get twoFactorAuthenticationEnabled(): boolean {
    return this.activeUserInfo?.two_factor_auth_enabled ?? false;
  }

  @Mutation
  setPasswordConfirmationRequired(required: boolean) {
    this.passwordConfirmationRequired = required;
  }
  @Mutation
  setTwoFactorChallengeActivated(active: boolean) {
    this.twoFactorChallengeActivated = active;
  }

  @Mutation
  setTwoFactorAuthEnabled(enabled: boolean) {
    if (this.activeUserInfo) {
      this.activeUserInfo.two_factor_auth_enabled = enabled;
    }
  }
  @Mutation
  setMaintenanceMode(enabled: boolean) {
    this.maintenanceModeEnabled = enabled;
  }

  @Mutation
  setPermissions(permissions: Permission[]) {
    if (this.activeUserInfo) {
      this.activeUserInfo.permissions = permissions;
    }
  }

  @Mutation
  setActiveUserInfo(userInfo: UserInfo | null) {
    this.activeUserInfo = userInfo;
  }

  @Mutation
  setImpersonationActive(active: boolean) {
    this.impersonationActive = active;
  }

  @Action({ rawError: true })
  login(payload: { email: string; password: string }) {
    return this.backendApi
      .login(payload.email, payload.password)
      .then((userInfo) => {
        if (userInfo.two_factor) {
          this.setTwoFactorChallengeActivated(true);
        } else if (userInfo.is_student || userInfo.is_admin) {
          // @ts-ignore
          this.setActiveUserInfo(userInfo);
          this.requestCurrentSchoolYear();
          return true;
        } else {
          return this.logout();
        }
      });
  }

  @Action({ rawError: true })
  logout() {
    return this.backendApi.logout().then(() => {
      this.setActiveUserInfo(null);
      this.setCurrentSchoolYear(null);
      return true;
    });
  }

  @Action({ rawError: true })
  stopImpersonate() {
    return this.backendApi.stopImpersonate().then(() => {
      this.setImpersonationActive(false);
    });
  }

  @Mutation
  setIsLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setCurrentSchoolYear(year: SchoolYear | null) {
    this.currentSchoolYear = year;
  }

  @Action
  requestCurrentSchoolYear() {
    return this.backendApi
      .apolloQuery<SchoolYear>("currentSchoolYear", currentSchoolYear)
      .then((currentYear) => {
        this.setCurrentSchoolYear(currentYear);
        return true;
      });
  }
}
