

















































import Component, { mixins } from "vue-class-component";
import { HumanFriendlyError } from "@/models/HumanFriendlyError";
import TYPES from "@/types";
import { lazyInject } from "@/inversify.config";
import { AbstractApiError } from "@/errors/AbstractApiError";
import IDialog from "@/views/components/common/dialog/IDialog.vue";
import VisibleVModel from "@/views/mixins/VisibleVModel.vue";
import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import { getModule } from "vuex-module-decorators";
import PermissionsModule from "@/modules/PermissionsModule";
import DialogSaveButton from "@/views/components/common/buttons/dialog/DialogSaveButton.vue";

@Component({
  components: { DialogSaveButton, IDialog },
})
export default class TwoFactorChallenge extends mixins(VisibleVModel) {
  @lazyInject(TYPES.IGraphQLBackendApi)
  protected backendApi!: IGraphQLBackendApi;
  protected permissionModule = getModule(PermissionsModule);
  protected errors: HumanFriendlyError = new HumanFriendlyError();
  protected loading = false;
  protected code: string = "";

  protected useRecoveryCode: boolean = false;

  protected get title(): string {
    return this.$t("twoFactorChallenge").toString();
  }

  protected reset() {
    this.code = "";
    this.useRecoveryCode = false;
  }

  protected confirmChallenge() {
    this.loading = true;
    this.errors.clear();

    this.backendApi
      .twoFactorChallenge(this.code, this.useRecoveryCode)
      .then((userInfo) => {
        this.permissionModule.setTwoFactorChallengeActivated(false);
        this.$emit("twoFactorChallengeCompleted", userInfo);
      })
      .catch((error: Error) => {
        if (error instanceof AbstractApiError) {
          this.errors.apiError = error;
        }
        this.$notificationService.reportError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  protected cancel() {
    this.errors.clear();
    this.$emit("canceled");
  }
}
